const timeOptionsMapper = {
  /**
   * Return array of time options for given day and place
   * @param {String} dayName
   * @param {Object} place
   * @return {Array}
   */
  mapTimeOptions: function(dayName, place) {
    let options = [];
    let minTime = place.openingHours[dayName.toLowerCase()].from;
    let maxTime = place.openingHours[dayName.toLowerCase()].to;
    if (!minTime || !maxTime) {
      return options;
    }
    let startHour = parseInt(minTime.slice(0, 2));
    let endHour = parseInt(maxTime.slice(0, 2));
    for (let i = startHour; i < endHour; i++) {
      for (let j = 0; j < 60; j += 5) {
        let timeSlot =
          String(i).padStart(2, "0") + ":" + String(j).padStart(2, "0");
        let option = {
          text: timeSlot,
          value: timeSlot
        };
        options.push(option);
      }
    }
    return options;
  }
};

export default timeOptionsMapper;

<template>
    <div
    id="multiple_client_select"
    ref="multiple_client_select"
    class="offcanvas offcanvas-right"
  >
    <button id="multiple_client_select_toggle" style="display: none;"></button>
    <!--begin::Header-->
    <div
      class="offcanvas-header d-flex align-items-center justify-content-between pb-5"
    >
        <h4 class="font-weight-bold m-0">
        {{ $t("multipleClientSelect.title") }}
        </h4>
        <a
            href="#"
            class=""
            id="multiple_client_select_close"
            @click="closeOffcanvas('multiple_client_select')"
        >
            <i class="ki ki-close icon-md"></i>
        </a>
    </div>
    <!--end::Header-->
    <!--begin::Content-->
    <perfect-scrollbar
      class="offcanvas-content mr-n5 scroll"
      style="max-height: 87vh; position: relative;"
    >
        <div class="bb-input-group search-input">
            <i class="fas fa-search"></i>
            <input
            type="text"
            v-model="searchTerm"
            :placeholder="$t('multipleClientSelect.searchPlaceholder')"
            />
            <button class="bb-badge badge-rounded" @click="searchTerm = ''">
                <i class="ki ki-close icon-md"></i>
            </button>
        </div>
        <div class="clients-options-container">
            <BBCheckboxGroup 
                :options="clientsOptions"
                v-model="selectedClientsIds"
                elementId="clients_checkboxes"
                :allCheckedLabel="
                $t('multipleClientSelect.selectAllLabel')
                "
            />
        </div>
    </perfect-scrollbar>
    <!--end::Content-->
    <!--begin::Footer-->
    <div class="offcanvas-footer">
      <button class="bb-btn btn-success" @click="selectClients()">
        {{ $t('multipleClientSelect.saveBtn') }}
      </button>
    </div>
    <!--end::Footer-->
  </div>
</template>
<script>
import MultipleClientSelectLayout from "@/assets/js/layout/extended/multiple-client-select.js";
import BBCheckboxGroup from "@/view/content/BBForm/BBCheckboxGroup.vue";
import { mapGetters } from "vuex";
import { CLOSE_ELEMENT } from "@/core/services/store/offcanvas.module.js";
import { SET_FORM_FIELD } from "@/core/services/store/form.module.js";

export default {
    name: "MultipleClientSelect",
    components: { BBCheckboxGroup },
    data() {
        return {
            formKey: null,
            searchTerm: "",
            selectedClientsIds: []
        };
    },
    mounted() {
        // Init Multiple select client Panel
        MultipleClientSelectLayout.init(this.$refs["multiple_client_select"]);
    },
    methods: {
        /**
         * Dispach CLOSE_ELEMENT action from offcanvas module
         * @return {void}
         */
        closeOffcanvas(elementId) {
            this.$store.dispatch(CLOSE_ELEMENT, elementId);
        },

        /**
         * Close mobileClientSelect element by imitating click on mobile_client_select_close element
         * @return {void}
         */
        close() {
            document.querySelector("#multiple_client_select_close").click();
        },

        /**
         * Save clientIds value to form with this form key
         * @return {void}
         */
        selectClients() {
            this.$store.dispatch(SET_FORM_FIELD, {
                formKey: this.formKey,
                fieldName: "clientIds",
                value: this.selectedClientsIds
            });
            if (
                document.querySelector("#multiple_client_select").hasAttribute("form-key")
            ) {
                document
                .querySelector("#multiple_client_select")
                .removeAttribute("form-key");
            }
            this.close();
        }
    },
    computed: {
        ...mapGetters(["openedElements", "clientsAsOptions"]),

        /**
         * Get form from form module
         * @return {Object|null}
         */
        formState() {
            if (this.formKey) {
                return this.$store.getters.getForm(this.formKey);
            }
            return null;
        },

        /**
         * Return array of clients to display
         * @return {Array}
         */
        clientsOptions() {
            if (this.clientsAsOptions === 0) {
                return [];
            }
            return this.clientsAsOptions.filter(client =>
                client.text.toLowerCase().includes(this.searchTerm.toLowerCase())
            );
        }
    },
    watch: {
    /**
     * Watch for opened elements change
     */
    openedElements() {
      if (this.openedElements.includes("multiple_client_select")) {
        if (
          document
            .querySelector("#multiple_client_select")
            .hasAttribute("form-key")
        ) {
            this.formKey = document
                .querySelector("#multiple_client_select")
                .getAttribute("form-key");

            if(this.formState) {
                this.selectedClientsIds = this.formState.fields.clientIds
            }
        }
      }
    }
  }
}
</script>
<style lang="scss">
@import "@/assets/sass/bb-styles/components/multiple-client-select.scss";
</style>
<template>
  <!-- begin:: Aside -->
  <div
    class="aside aside-left aside-fixed d-flex flex-column flex-row-auto"
    id="kt_aside"
    ref="kt_aside"
    @mouseover="mouseEnter"
    @mouseleave="mouseLeave"
  >
    <!-- begin:: Aside -->
    <KTBrand></KTBrand>
    <!-- end:: Aside -->

    <!-- begin:: Aside Menu -->
    <div
      class="aside-menu-wrapper flex-column-fluid"
      id="kt_aside_menu_wrapper"
    >
      <div class="bb-aside-mobile-brand">
        <!-- <img :src="mobileBrandLogoPath" /> -->
        <svg
          width="115"
          height="20"
          viewBox="0 0 703 121"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M146.142 41.5873C148.069 38.7202 150.71 36.3907 154.066 34.5988C157.492 32.8068 161.383 31.9109 165.737 31.9109C170.805 31.9109 175.374 33.1652 179.443 35.6739C183.584 38.1826 186.832 41.7665 189.187 46.4255C191.614 51.0128 192.828 56.3528 192.828 62.4453C192.828 68.5379 191.614 73.9495 189.187 78.6802C186.832 83.3392 183.584 86.9589 179.443 89.5393C175.374 92.1197 170.805 93.4099 165.737 93.4099C161.311 93.4099 157.421 92.5497 154.066 90.8295C150.782 89.0375 148.14 86.7439 146.142 83.9485V92.4422H131.151V12.8806H146.142V41.5873ZM177.516 62.4453C177.516 58.8615 176.766 55.7794 175.267 53.199C173.839 50.5469 171.912 48.54 169.485 47.1781C167.129 45.8162 164.559 45.1353 161.775 45.1353C159.063 45.1353 156.493 45.8521 154.066 47.2856C151.71 48.6475 149.782 50.6545 148.283 53.3065C146.856 55.9586 146.142 59.0765 146.142 62.6604C146.142 66.2442 146.856 69.3622 148.283 72.0142C149.782 74.6663 151.71 76.7091 154.066 78.1426C156.493 79.5045 159.063 80.1854 161.775 80.1854C164.559 80.1854 167.129 79.4686 169.485 78.0351C171.912 76.6016 173.839 74.5588 175.267 71.9067C176.766 69.2547 177.516 66.1009 177.516 62.4453Z"
            fill="black"
          />
          <path
            d="M225.78 93.4099C220.069 93.4099 214.929 92.1555 210.361 89.6468C205.792 87.0664 202.187 83.4467 199.546 78.7877C196.976 74.1287 195.691 68.7529 195.691 62.6604C195.691 56.5678 197.011 51.192 199.653 46.533C202.365 41.874 206.042 38.2902 210.682 35.7815C215.322 33.2011 220.497 31.9109 226.208 31.9109C231.919 31.9109 237.095 33.2011 241.735 35.7815C246.375 38.2902 250.015 41.874 252.657 46.533C255.369 51.192 256.726 56.5678 256.726 62.6604C256.726 68.7529 255.334 74.1287 252.55 78.7877C249.837 83.4467 246.125 87.0664 241.413 89.6468C236.773 92.1555 231.562 93.4099 225.78 93.4099ZM225.78 80.2929C228.493 80.2929 231.027 79.6478 233.382 78.3577C235.81 76.9958 237.737 74.9888 239.165 72.3368C240.592 69.6847 241.306 66.4593 241.306 62.6604C241.306 56.9979 239.807 52.6614 236.809 49.651C233.882 46.5689 230.277 45.0278 225.994 45.0278C221.711 45.0278 218.106 46.5689 215.179 49.651C212.324 52.6614 210.896 56.9979 210.896 62.6604C210.896 68.3229 212.288 72.6952 215.072 75.7773C217.927 78.7877 221.497 80.2929 225.78 80.2929Z"
            fill="black"
          />
          <path
            d="M289.78 93.4099C284.069 93.4099 278.93 92.1555 274.361 89.6468C269.792 87.0664 266.187 83.4467 263.546 78.7877C260.976 74.1287 259.691 68.7529 259.691 62.6604C259.691 56.5678 261.012 51.192 263.653 46.533C266.366 41.874 270.042 38.2902 274.682 35.7815C279.322 33.2011 284.498 31.9109 290.209 31.9109C295.92 31.9109 301.095 33.2011 305.735 35.7815C310.375 38.2902 314.016 41.874 316.657 46.533C319.37 51.192 320.726 56.5678 320.726 62.6604C320.726 68.7529 319.334 74.1287 316.55 78.7877C313.837 83.4467 310.125 87.0664 305.414 89.6468C300.774 92.1555 295.563 93.4099 289.78 93.4099ZM289.78 80.2929C292.493 80.2929 295.027 79.6478 297.383 78.3577C299.81 76.9958 301.737 74.9888 303.165 72.3368C304.593 69.6847 305.307 66.4593 305.307 62.6604C305.307 56.9979 303.808 52.6614 300.809 49.651C297.883 46.5689 294.278 45.0278 289.995 45.0278C285.711 45.0278 282.106 46.5689 279.18 49.651C276.324 52.6614 274.896 56.9979 274.896 62.6604C274.896 68.3229 276.288 72.6952 279.072 75.7773C281.928 78.7877 285.497 80.2929 289.78 80.2929Z"
            fill="black"
          />
          <path
            d="M362.561 92.4422L342.431 67.0685V92.4422H327.439V12.8806H342.431V58.1447L362.347 32.8785H381.836L355.708 62.7679L382.05 92.4422H362.561Z"
            fill="black"
          />
          <path
            d="M447.688 32.8785L410.96 120.611H395.006L407.855 90.937L384.084 32.8785H400.895L416.207 74.4871L431.734 32.8785H447.688Z"
            fill="black"
          />
          <path
            d="M468.163 41.5873C470.09 38.7202 472.731 36.3907 476.087 34.5988C479.513 32.8068 483.404 31.9109 487.758 31.9109C492.827 31.9109 497.395 33.1652 501.464 35.6739C505.605 38.1826 508.853 41.7665 511.208 46.4255C513.635 51.0128 514.849 56.3528 514.849 62.4453C514.849 68.5379 513.635 73.9495 511.208 78.6802C508.853 83.3392 505.605 86.9589 501.464 89.5393C497.395 92.1197 492.827 93.4099 487.758 93.4099C483.332 93.4099 479.442 92.5497 476.087 90.8295C472.803 89.0375 470.162 86.7439 468.163 83.9485V92.4422H453.172V52.6614V12.8806H468.163V41.5873ZM499.537 62.4453C499.537 58.8615 498.787 55.7794 497.288 53.199C495.86 50.5469 493.933 48.54 491.506 47.1781C489.15 45.8162 486.58 45.1353 483.796 45.1353C481.084 45.1353 478.514 45.8521 476.087 47.2856C473.731 48.6475 471.803 50.6545 470.304 53.3065C468.877 55.9586 468.163 59.0765 468.163 62.6604C468.163 66.2442 468.877 69.3622 470.304 72.0142C471.803 74.6663 473.731 76.7091 476.087 78.1426C478.514 79.5045 481.084 80.1854 483.796 80.1854C486.58 80.1854 489.15 79.4686 491.506 78.0351C493.933 76.6016 495.86 74.5588 497.288 71.9067C498.787 69.2547 499.537 66.1009 499.537 62.4453Z"
            fill="black"
          />
          <path
            d="M546.73 93.4099C541.019 93.4099 535.879 92.1555 531.311 89.6468C526.742 87.0664 523.137 83.4467 520.496 78.7877C517.926 74.1287 516.641 68.7529 516.641 62.6604C516.641 56.5678 517.962 51.192 520.603 46.533C523.316 41.874 526.992 38.2902 531.632 35.7815C536.272 33.2011 541.448 31.9109 547.158 31.9109C552.869 31.9109 558.045 33.2011 562.685 35.7815C567.325 38.2902 570.966 41.874 573.607 46.533C576.32 51.192 577.676 56.5678 577.676 62.6604C577.676 68.7529 576.284 74.1287 573.5 78.7877C570.787 83.4467 567.075 87.0664 562.364 89.6468C557.724 92.1555 552.512 93.4099 546.73 93.4099ZM546.73 80.2929C549.443 80.2929 551.977 79.6478 554.333 78.3577C556.76 76.9958 558.687 74.9888 560.115 72.3368C561.543 69.6847 562.257 66.4593 562.257 62.6604C562.257 56.9979 560.757 52.6614 557.759 49.651C554.832 46.5689 551.227 45.0278 546.944 45.0278C542.661 45.0278 539.056 46.5689 536.129 49.651C533.274 52.6614 531.846 56.9979 531.846 62.6604C531.846 68.3229 533.238 72.6952 536.022 75.7773C538.878 78.7877 542.447 80.2929 546.73 80.2929Z"
            fill="black"
          />
          <path
            d="M610.731 93.4099C605.02 93.4099 599.88 92.1555 595.311 89.6468C590.743 87.0664 587.138 83.4467 584.496 78.7877C581.926 74.1287 580.641 68.7529 580.641 62.6604C580.641 56.5678 581.962 51.192 584.603 46.533C587.316 41.874 590.992 38.2902 595.633 35.7815C600.273 33.2011 605.448 31.9109 611.159 31.9109C616.87 31.9109 622.045 33.2011 626.685 35.7815C631.325 38.2902 634.966 41.874 637.607 46.533C640.32 51.192 641.676 56.5678 641.676 62.6604C641.676 68.7529 640.284 74.1287 637.5 78.7877C634.788 83.4467 631.076 87.0664 626.364 89.6468C621.724 92.1555 616.513 93.4099 610.731 93.4099ZM610.731 80.2929C613.443 80.2929 615.977 79.6478 618.333 78.3577C620.76 76.9958 622.688 74.9888 624.115 72.3368C625.543 69.6847 626.257 66.4593 626.257 62.6604C626.257 56.9979 624.758 52.6614 621.76 49.651C618.833 46.5689 615.228 45.0278 610.945 45.0278C606.662 45.0278 603.057 46.5689 600.13 49.651C597.274 52.6614 595.847 56.9979 595.847 62.6604C595.847 68.3229 597.239 72.6952 600.023 75.7773C602.878 78.7877 606.447 80.2929 610.731 80.2929Z"
            fill="black"
          />
          <path
            d="M683.512 92.4422L663.381 67.0685V92.4422H648.39V12.8806H663.381V58.1447L683.297 32.8785H702.786L676.659 62.7679L703 92.4422H683.512Z"
            fill="black"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9.36788 0C4.19415 0 0 4.19414 0 9.36788V100.705C0 105.879 4.19414 110.073 9.36788 110.073H60.8913V110.066C77.1113 109.728 90.1637 95.4428 90.1637 77.8698C90.1637 64.9109 83.0657 53.7399 72.8337 48.6316L39.3306 82.1347L35.364 78.1682L35.3597 78.1725L16.3936 59.2063L26.5942 49.0057L39.3263 61.7378L66.7351 34.329C66.7414 34.0115 66.7445 33.6928 66.7445 33.3731C66.7445 14.9416 56.2591 0 43.3248 0C42.9512 0 42.5796 0.0124666 42.2103 0.037124C41.8049 0.0124942 41.3961 0 40.9845 0H9.36788Z"
            fill="#1A73E8"
          />
        </svg>
        <button @click="closeMobileAside()">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-x"
          >
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
        </button>
      </div>
      <div
        ref="kt_aside_menu"
        id="kt_aside_menu"
        class="aside-menu my-4"
        data-menu-vertical="1"
        data-menu-dropdown-timeout="500"
        v-bind:class="asideMenuClass"
      >
        <!-- example static menu here -->
        <perfect-scrollbar
          class="aside-menu scroll"
          style="max-height: 90vh; position: relative;"
        >
          <KTMenu></KTMenu>
        </perfect-scrollbar>
      </div>
    </div>
  </div>
  <!-- end:: Aside -->
</template>

<script>
import { mapGetters } from "vuex";
import KTBrand from "@/view/layout/brand/Brand.vue";

import KTLayoutAside from "@/assets/js/layout/base/aside.js";
import KTLayoutAsideMenu from "@/assets/js/layout/base/aside-menu.js";
import KTMenu from "@/view/layout/aside/Menu.vue";
import mobileAsideMixin from "@/core/mixins/mobileAsideMixin.js";

export default {
  name: "KTAside",
  data() {
    return {
      insideTm: 0,
      outsideTm: 0
    };
  },
  mixins: [mobileAsideMixin],
  components: {
    KTBrand,
    KTMenu
  },
  mounted() {
    this.$nextTick(() => {
      // Init Aside
      KTLayoutAside.init(this.$refs["kt_aside"]);

      // Init Aside Menu
      KTLayoutAsideMenu.init(this.$refs["kt_aside_menu"]);
    });
  },
  methods: {
    /**
     * Use for fixed left aside menu, to show menu on mouseenter event.
     */
    mouseEnter() {
      if (this.layoutConfig("aside.self.minimize.hoverable")) {
        // check if the left aside menu is fixed
        if (document.body.classList.contains("aside-fixed")) {
          if (this.outsideTm) {
            clearTimeout(this.outsideTm);
            this.outsideTm = null;
          }

          // if the left aside menu is minimized
          if (document.body.classList.contains("aside-minimize")) {
            document.body.classList.add("aside-minimize-hover");

            // show the left aside menu
            document.body.classList.remove("aside-minimize");
          }
        }
      }
    },

    /**
     * Use for fixed left aside menu, to show menu on mouseenter event.
     */
    mouseLeave() {
      if (this.layoutConfig("aside.self.minimize.hoverable")) {
        if (document.body.classList.contains("aside-fixed")) {
          if (this.insideTm) {
            clearTimeout(this.insideTm);
            this.insideTm = null;
          }

          if (document.querySelector(".aside-menu .scroll")) {
            document.querySelector(".aside-menu .scroll").scrollTop = 0;
          }

          // if the left aside menu is expand
          if (document.body.classList.contains("aside-minimize-hover")) {
            // hide back the left aside menu
            document.body.classList.remove("aside-minimize-hover");
            document.body.classList.add("aside-minimize");
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters(["layoutConfig", "getClasses"]),

    /**
     * Get extra classes for menu based on the options
     */
    asideMenuClass() {
      const classes = this.getClasses("aside_menu");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },

    /**
     * Return logo path for aside mobile brand
     * @return {String}
     */
    mobileBrandLogoPath() {
      return process.env.BASE_URL + "media/logos/bb_logo.png";
    }
  }
};
</script>

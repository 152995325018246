<template>
  <!-- begin:: Header -->
  <div
    id="kt_header"
    ref="kt_header"
    class="header"
    v-bind:class="headerClasses"
  >
    <div
      class="container-fluid d-flex align-items-center justify-content-between"
    >
      <!-- begin:: Header Menu -->
      <div
        class="header-menu-wrapper header-menu-wrapper-left"
        ref="kt_header_menu_wrapper"
      >
        <div
          v-if="headerMenuEnabled"
          id="kt_header_menu"
          ref="kt_header_menu"
          class="header-menu header-menu-mobile"
          v-bind:class="headerMenuClasses"
        >
          <!-- example static menu here -->
          <h5 class="text-dark font-weight-bold my-2 mr-5 page-title">
            {{ title }}
          </h5>
        </div>
      </div>
      <div id="kt_aside_mobile_toggle" style="display: none"></div>
      <div id="kt_aside_close_btn" style="display: none"></div>
      <!-- end:: Header Menu -->
      <KTTopbar></KTTopbar>
      <!-- start:: Offcanvas elements -->
      <AppointmentForm />
      <BlockTimeForm />
      <MobileClientSelect />
      <MobileSubjectSelect />
      <MobileServiceSelect />
      <!-- <ClientForm /> -->
      <!-- <CalendarForm /> -->
      <!-- <ServiceForm /> -->
      <DateInput />
      <AvailabilityInput />
      <OpeningHoursForm />
      <DeleteClientModal />
      <DeleteSubjectModal />
      <DeleteStaffModal />
      <MultipleClientSelect />
      <div class="offcanvas-overlay" v-if="overlayShow"></div>
      <!-- end:: Offcanvas elements -->
    </div>
  </div>
  <!-- end:: Header -->
</template>

<style lang="scss" scoped>
@import "@/assets/sass/bb-styles/scoped/header.scss";
</style>

<script>
import { mapGetters } from "vuex";
import KTTopbar from "@/view/layout/header/Topbar.vue";
import KTLayoutHeader from "@/assets/js/layout/base/header.js";
import KTLayoutHeaderMenu from "@/assets/js/layout/base/header-menu.js";
import AppointmentForm from "@/view/layout/extras/offcanvas/AppointmentForm.vue";
//import ClientForm from "@/view/layout/extras/offcanvas/ClientForm.vue";
//import CalendarForm from "@/view/layout/extras/offcanvas/CalendarForm.vue";
//import ServiceForm from "@/view/layout/extras/offcanvas/ServiceForm.vue";
import BlockTimeForm from "@/view/layout/extras/offcanvas/BlockTimeForm.vue";
import DateInput from "@/view/layout/extras/offcanvas/DateInput.vue";
import AvailabilityInput from "@/view/layout/extras/offcanvas/AvailabilityInput.vue";
import OpeningHoursForm from "@/view/layout/extras/offcanvas/OpeningHoursForm.vue";
import MobileClientSelect from "@/view/layout/extras/offcanvas/MobileClientSelect.vue";
import MobileSubjectSelect from "@/view/layout/extras/offcanvas/MobileSubjectSelect.vue";
import MobileServiceSelect from "@/view/layout/extras/offcanvas/MobileServiceSelect.vue";
import DeleteClientModal from "@/view/layout/extras/modal/DeleteClientModal.vue";
import DeleteSubjectModal from "@/view/layout/extras/modal/DeleteSubjectModal.vue";
import DeleteStaffModal from "@/view/layout/extras/modal/DeleteStaffModal.vue";
import MultipleClientSelect from "@/view/layout/extras/offcanvas/MultipleClientSelect.vue";

export default {
  name: "KTHeader",
  props: {
    title: String,
    overlayShow: Boolean,
  },
  components: {
    KTTopbar,
    AppointmentForm,
    //ClientForm,
    //CalendarForm,
    //ServiceForm,
    BlockTimeForm,
    DateInput,
    AvailabilityInput,
    OpeningHoursForm,
    MobileClientSelect,
    MobileSubjectSelect,
    MobileServiceSelect,
    DeleteClientModal,
    DeleteSubjectModal,
    DeleteStaffModal,
    MultipleClientSelect
  },
  mounted() {
    // Init Desktop & Mobile Headers
    KTLayoutHeader.init("kt_header", "kt_header_mobile");

    // Init Header Menu
    KTLayoutHeaderMenu.init(
      this.$refs["kt_header_menu"],
      this.$refs["kt_header_menu_wrapper"]
    );
  },
  computed: {
    ...mapGetters(["layoutConfig", "getClasses"]),

    /**
     * Check if the header menu is enabled
     * @returns {boolean}
     */
    headerMenuEnabled() {
      return !!this.layoutConfig("header.menu.self.display");
    },

    /**
     * Get extra classes for header based on the options
     * @returns {null|*}
     */
    headerClasses() {
      const classes = this.getClasses("header");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },

    /**
     * Get extra classes for header menu based on the options
     * @returns {null|*}
     */
    headerMenuClasses() {
      const classes = this.getClasses("header_menu");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },
  },
};
</script>

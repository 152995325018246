const calendarForm = {
  /**
   * Calendar form key
   * @type {String}
   */
  key: "calendarForm",

  /**
   * Calendar form fields object
   * @type {Object}
   */
  fields: {
    avatar: null,
    name: "",
    title: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    permissions: [],
    workingHours: {
      monday: [
        {
          from: "06:00",
          to: "23:55",
        },
      ],
      tuesday: [
        {
          from: "06:00",
          to: "23:55",
        },
      ],
      wednesday: [
        {
          from: "06:00",
          to: "23:55",
        },
      ],
      thursday: [
        {
          from: "06:00",
          to: "23:55",
        },
      ],
      friday: [
        {
          from: "06:00",
          to: "23:55",
        },
      ],
      saturday: [
        {
          from: null,
          to: null,
        },
      ],
      sunday: [
        {
          from: null,
          to: null,
        },
      ],
    },
    daysOff: [],
    placeId: null,
    serviceIds: [],
    requiredInfo: [],
    otherAlias: "",
    cancellationMargin: 0,
    bookingMargin: 0,
    toc: "",
    spBookingConfirmation: true,
    spCancellationConfirmation: true,
    bookingConfirmation: true,
    cancellationConfirmation: true,
    bookingReminder: true,
    followUp: true,
    followUpDays: "28",
    //new fields
    color: "#1A73E8",
    placeIds: [],
    onlineBookings: true,
    employmentStartDate: null,
    employmentEndDate: null,
    clientProfileIds: [],
  },

  /**
   * Calendar form errors object
   * @type {Object}
   */
  errors: {},

  /**
   * Calendar form mode
   * @type {String}
   */
  mode: "create",

  /**
   * Calendar form path
   * @type {String}
   */
  path: "/api/b2b/calendars",

  /**
   * Calendar form helpers
   * @type {Object}
   */
  helpers: {
    avatar: null,
    requiredInfoObject: {
      firstName: true,
      lastName: true,
      email: true,
      phone: true,
      address: false,
      zipCode: false,
      city: false,
      other: false,
    },
    cancellationMarginObject: {
      days: "1",
      hours: "0",
      minutes: "0",
    },
    bookingMarginObject: {
      days: "0",
      hours: "0",
      minutes: "15",
    },
    permissionsObject: {
      manageAccount: true,
      managePlace: false,
      manageBookings: false,
      manageOwnCalendar: true,
    },
    permissionValue: 0,
  },
};

export default calendarForm;

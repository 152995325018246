<template>
  <ul class="menu-nav">
    <router-link
      v-on:click.native="closeOffcanvasElements()"
      :to="{ name: 'calendar' }"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <!-- <i class="menu-icon far fa-calendar"></i> -->
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="menu-icon feather feather-calendar"
          >
            <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
            <line x1="16" y1="2" x2="16" y2="6"></line>
            <line x1="8" y1="2" x2="8" y2="6"></line>
            <line x1="3" y1="10" x2="21" y2="10"></line>
          </svg>
          <span class="menu-text">{{ $t("menu.calendar") }}</span>
        </a>
        <i class="la la-angle-right"></i>
      </li>
    </router-link>
    <router-link
      v-on:click.native="closeOffcanvasElements()"
      :to="{ name: 'clients' }"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="menu-icon feather feather-users"
          >
            <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
            <circle cx="9" cy="7" r="4"></circle>
            <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
            <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
          </svg>
          <span class="menu-text">{{ $t("menu.clients") }}</span>
        </a>
        <i class="la la-angle-right"></i>
      </li>
    </router-link>
    <router-link
      v-on:click.native="closeOffcanvasElements()"
      :to="{ name: 'services' }"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="
        $hasRole(acl.roles.owner) ||
          $hasPermission(acl.permissions.manageAccount)
      "
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <!-- <i class="menu-icon flaticon2-list-3"></i> -->
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="menu-icon feather feather-clipboard"
          >
            <path
              d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"
            ></path>
            <rect x="8" y="2" width="8" height="4" rx="1" ry="1"></rect>
          </svg>
          <span class="menu-text">{{ $t("menu.services") }}</span>
        </a>
        <i class="la la-angle-right"></i>
      </li>
    </router-link>
    <router-link
      v-on:click.native="closeOffcanvasElements()"
      :to="{ name: 'staff' }"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="
        $hasRole(acl.roles.owner) ||
          $hasPermission(acl.permissions.manageAccount) ||
          $hasPermission(acl.permissions.managePlace)
      "
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <!-- <i class="menu-icon la la-suitcase"></i> -->
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="menu-icon feather feather-briefcase"
          >
            <rect x="2" y="7" width="20" height="14" rx="2" ry="2"></rect>
            <path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16"></path>
          </svg>
          <span class="menu-text">{{ $t("menu.staff") }}</span>
        </a>
        <i class="la la-angle-right"></i>
      </li>
    </router-link>
    <router-link
      v-show="this.currentUser.isInLawIndustry"
      v-on:click.native="closeOffcanvasElements()"
      :to="{ name: 'subjects' }"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="
        $hasRole(acl.roles.owner) ||
          $hasPermission(acl.permissions.manageAccount) ||
          $hasPermission(acl.permissions.managePlace)
      "
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <svg 
            xmlns="http://www.w3.org/2000/svg" 
            viewBox="0 0 512 512"
            width="22"
            height="22"  
            fill="#EAF0F6"
            class="menu-icon feather feather-briefcase"
          >
            <path 
              d="M502.6 198.6l-61.25-61.25C435.4 131.4 427.3 128 418.8 128H256C220.7 128 191.1 156.7 192 192l.0065 255.1C192 483.3 220.7 512 256 512h192c35.2 0 64-28.8 64-64l.0098-226.7C512 212.8 508.6 204.6 502.6 198.6zM464 448c0 8.836-7.164 16-16 16h-192c-8.838 0-16-7.164-16-16L240 192.1c0-8.836 7.164-16 16-16h128L384 224c0 17.67 14.33 32 32 32h48.01V448zM317.7 96C310.6 68.45 285.8 48 256 48H215.2C211.3 20.93 188.1 0 160 0C131.9 0 108.7 20.93 104.8 48H64c-35.35 0-64 28.65-64 64V384c0 35.34 28.65 64 64 64h96v-48H64c-8.836 0-16-7.164-16-16V112C48 103.2 55.18 96 64 96h16v16c0 17.67 14.33 32 32 32h61.35C190 115.4 220.6 96 256 96H317.7zM160 72c-8.822 0-16-7.176-16-16s7.178-16 16-16s16 7.176 16 16S168.8 72 160 72z"
            />
          </svg>
          <span class="menu-text">{{ $t("menu.subjects") }}</span>
        </a>
        <i class="la la-angle-right"></i>
      </li>
    </router-link>
    <router-link
      v-on:click.native="closeOffcanvasElements()"
      :to="{ name: 'statistics' }"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="
        $hasRole(acl.roles.owner) ||
          $hasPermission(acl.permissions.manageAccount)
      "
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <!-- <i class="menu-icon flaticon2-pie-chart-4"></i> -->
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="menu-icon feather feather-pie-chart"
          >
            <path d="M21.21 15.89A10 10 0 1 1 8 2.83"></path>
            <path d="M22 12A10 10 0 0 0 12 2v10z"></path>
          </svg>
          <span class="menu-text">{{ $t("menu.statistics") }}</span>
        </a>
        <i class="la la-angle-right"></i>
      </li>
    </router-link>
    <router-link
      v-on:click.native="closeOffcanvasElements()"
      :to="{ name: 'settings' }"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="
        $hasRole(acl.roles.owner) ||
          $hasPermission(acl.permissions.manageAccount) ||
          $hasPermission(acl.permissions.managePlace)
      "
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <!-- <i class="menu-icon flaticon2-pie-chart-4"></i> -->
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="menu-icon feather feather-settings"
          >
            <circle cx="12" cy="12" r="3"></circle>
            <path
              d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"
            ></path>
          </svg>
          <span class="menu-text">{{ $t("menu.settings") }}</span>
        </a>
        <i class="la la-angle-right"></i>
      </li>
    </router-link>
    <router-link
      v-on:click.native="closeOffcanvasElements()"
      :to="{ name: 'profile' }"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item mobile-only-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <!-- <i class="menu-icon flaticon2-pie-chart-4"></i> -->
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="menu-icon feather feather-user"
          >
            <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
            <circle cx="12" cy="7" r="4"></circle>
          </svg>
          <span class="menu-text">{{ $t("menu.profile") }}</span>
        </a>
        <i class="la la-angle-right"></i>
      </li>
    </router-link>
    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item mobile-only-item"
    >
      <a class="menu-link" @click="changeLanguage()">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-globe menu-icon"
        >
          <circle cx="12" cy="12" r="10"></circle>
          <line x1="2" y1="12" x2="22" y2="12"></line>
          <path
            d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z"
          ></path>
        </svg>
        <span class="menu-text">{{ currentLanguageName }}</span>
      </a>
      <i class="la la-angle-right"></i>
    </li>
    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item mobile-only-item"
    >
      <a class="menu-link" @click="logout()">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-log-out menu-icon"
        >
          <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
          <polyline points="16 17 21 12 16 7"></polyline>
          <line x1="21" y1="12" x2="9" y2="12"></line>
        </svg>
        <span class="menu-text">{{ $t("menu.logout") }}</span>
      </a>
      <i class="la la-angle-right"></i>
    </li>
    <ChangeLanguageModal ref="changeLanguageModal" />
  </ul>
</template>

<style lang="scss" scoped>
@import "@/assets/sass/bb-styles/scoped/aside-menu.scss";
</style>

<script>
import { CLOSE_ELEMENT } from "@/core/services/store/offcanvas.module.js";
import { mapGetters } from "vuex";
import { LOGOUT } from "@/core/services/store/auth.module";
import mobileAsideMixin from "@/core/mixins/mobileAsideMixin.js";
import i18nService from "@/core/services/i18n.service.js";
import ChangeLanguageModal from "@/view/layout/extras/modal/ChangeLanguageModal.vue";

export default {
  name: "KTMenu",
  mixins: [mobileAsideMixin],
  components: { ChangeLanguageModal },
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },

    /**
     * Close all offcanvas elements
     * @return {void}
     */
    closeOffcanvasElements() {
      this.openedElements.forEach(elementId => {
        document.querySelector("#" + elementId + "_close").click();
        this.$store.dispatch(CLOSE_ELEMENT, elementId);
      });
      this.closeMobileAside();
    },

    /**
     * Dispatch store logout action (log out authenticated user)
     * @return {void}
     */
    logout() {
      this.$store.dispatch(LOGOUT);
    },

    /**
     * Open change language modal
     * @return {void}
     */
    changeLanguage() {
      this.$refs.changeLanguageModal.show();
      this.closeMobileAside();
    }
  },
  computed: {
    ...mapGetters(["openedElements", "currentUser"]),

    /**
     * Return name of current language
     * @return {String}
     */
    currentLanguageName() {
      let selectedLanguage = i18nService.languages.find(
        language => language.lang === i18nService.getActiveLanguage()
      );
      return selectedLanguage.name;
    }
  }
};
</script>

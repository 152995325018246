<template>
  <b-modal
    id="change_language_modal"
    ref="bvModal"
    :no-close-on-backdrop="true"
  >
    <template v-slot:modal-header="{ close }">
      <h3>{{ $t("changeLanguageModal.title") }}</h3>
      <button @click="close" id="change_language_modal_close_btn">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="17"
          height="17"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-x"
        >
          <line x1="18" y1="6" x2="6" y2="18"></line>
          <line x1="6" y1="6" x2="18" y2="18"></line>
        </svg>
      </button>
    </template>
    <div class="bb-form-group">
      <label>{{ $t("changeLanguageModal.languageInput.label") }}</label>
      <BBSelect
        :options="languagesOptions"
        v-model="selectedLanguage"
      ></BBSelect>
    </div>
    <template v-slot:modal-footer>
      <button class="bb-btn btn-success" @click="save()">
        {{ $t("changeLanguageModal.saveBtn") }}
      </button>
    </template>
  </b-modal>
</template>

<script>
import BBSelect from "@/view/content/BBForm/BBSelect.vue";
import i18nService from "@/core/services/i18n.service.js";

export default {
  name: "ChangeLanguageModal",
  components: { BBSelect },
  data() {
    return {
      selectedLanguage: i18nService.getActiveLanguage()
    };
  },
  methods: {
    /**
     * Show the modal
     * @return {void}
     */
    show() {
      this.$refs.bvModal.show();
    },

    /**
     * Close the modal
     * @return {void}
     */
    closeModal() {
      document.querySelector("#change_language_modal_close_btn").click();
    },

    /**
     * Save selected language
     * @return {void}
     */
    save() {
      i18nService.setActiveLanguage(this.selectedLanguage);
      this.closeModal();
      window.location.reload();
    }
  },
  computed: {
    /**
     * Return array of language options
     * @return {Array}
     */
    languagesOptions() {
      let languageOptions = [];
      i18nService.languages.forEach(language => {
        let optionItem = {
          text: language.name,
          value: language.lang,
          flag: language.flag
        };
        languageOptions.push(optionItem);
      });

      return languageOptions;
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/sass/bb-styles/modals/change-language-modal.scss";
</style>

<template>
  <div class="subheader py-2 py-lg-4" id="settings_subheader">
    <div class="container-fluid d-flex align-items-center subheader-navigation">
      <router-link
        to="/settings/account-settings"
        class="subheader-navigation-item"
        :class="accountSettingsActive ? 'active' : ''"
        v-if="
          $hasRole(acl.roles.owner) ||
            $hasPermission(acl.permissions.manageAccount)
        "
      >
        {{ $t("settings.tabs.account") }}
      </router-link>
      <router-link
        to="/settings/locations"
        class="subheader-navigation-item"
        :class="locationsActive ? 'active' : ''"
        v-if="
          $hasRole(acl.roles.owner) ||
            $hasPermission(acl.permissions.manageAccount) ||
            $hasPermission(acl.permissions.managePlace)
        "
      >
        {{ $t("settings.tabs.locations") }}
      </router-link>
      <!-- <router-link
        to="/settings/billing"
        class="subheader-navigation-item"
        :class="billingActive ? 'active' : ''"
        v-if="
          $hasRole(acl.roles.owner) ||
            $hasPermission(acl.permissions.manageAccount)
        "
      >
        {{ $t("settings.tabs.billing") }}
      </router-link> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "SettingsSubheader",
  computed: {
    /**
     * Check if account settings link is active
     * @return {boolean}
     */
    accountSettingsActive() {
      if (!this.$route.params.tab) {
        return true;
      }
      return this.$route.params.tab === "account-settings";
    },

    /**
     * Check if locations link is active
     * @return {boolean}
     */
    locationsActive() {
      return this.$route.params.tab === "locations";
    },

    /**
     * Check if billing link is active
     * @return {boolean}
     */
    billingActive() {
      return this.$route.params.tab === "billing";
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/sass/bb-styles/components/settings-subheader.scss";
</style>

<template>
  <div
    id="appointment_form"
    ref="appointment_form"
    class="offcanvas offcanvas-right"
    v-click-outside="handleOutsideClick"
  >
    <button id="appointment_form_toggle" style="display: none"></button>
    <!--begin::Header-->
    <div
      class="offcanvas-header d-flex align-items-center justify-content-between pb-5"
    >
      <h4 class="font-weight-bold m-0">
        {{ title }}
      </h4>
      <a
        href="#"
        class=""
        id="appointment_form_close"
        @click="closeOffcanvas('appointment_form')"
      >
        <i class="ki ki-close icon-md"></i>
      </a>
    </div>
    <!--end::Header-->
    <!--begin::Content-->
    <perfect-scrollbar
      class="offcanvas-content mr-n5 scroll"
      style="max-height: 85vh; position: relative"
    >
      <div class="bb-form-group client-select" v-if="isRegularAppointmentForm">
        <div v-show="!selectedClient || !selectedSubject">
          <label v-if="currentUser.isInLawIndustry">{{
            $t("appointmentForm.subjectInput.label")
          }}</label>
          <label v-else>{{
            $t("appointmentForm.clientInput.label")
          }}</label>
        </div>
        <BBSelect
          v-if="!currentUser.isInLawIndustry"
          :options="clientsAsOptions"
          v-model="form.fields.clientId"
          :search="true"
          :placeholder="$t('appointmentForm.clientInput.placeholder')"
          :searchPlaceholder="
            $t('appointmentForm.clientInput.searchPlaceholder')
          "
          :noOptionsText="$t('appointmentForm.clientInput.noResultsText')"
        >
          <template v-if="selectedClient" v-slot:toggle="{ removeSelected }">
            <div
              class="badge-option-container"
              @click="clientDetailsVisible = !clientDetailsVisible"
            >
              <div class="badge-option">
                <div class="bb-badge">{{ selectedClient.initials }}</div>
                <div class="badge-option-text">
                  <span>{{ selectedClient.fullName }}</span>
                  <small>{{ selectedClient.email }}</small>
                </div>
              </div>
              <i :class="clientDetailsArrowClass"></i>
            </div>
            <div class="selected-client-details" v-show="clientDetailsVisible">
              <div class="selected-client-actions">
                <span
                  class="text-success mr-4"
                  @click="editClient(selectedClient)"
                  v-if="
                    $hasRole(acl.roles.owner) ||
                    $hasPermission(acl.permissions.manageAccount)
                  "
                  >{{ $t("appointmentForm.clientInput.editBtn") }}</span
                >
                <span class="text-danger" @click="removeSelected">{{
                  $t("appointmentForm.clientInput.removeBtn")
                }}</span>
              </div>
              <div class="selected-client-info">
                <h5>{{ $t("appointmentForm.clientInput.details.title") }}</h5>
                <div class="bb-client-profile-info-item">
                  <label>{{
                    $t("appointmentForm.clientInput.details.emailLabel")
                  }}</label>
                  <span>{{ selectedClient.email }}</span>
                </div>
                <div class="bb-client-profile-info-item">
                  <label>{{
                    $t("appointmentForm.clientInput.details.phoneLabel")
                  }}</label>
                  <span>{{ selectedClient.phone }}</span>
                </div>
                <div class="bb-client-profile-info-item">
                  <label>{{
                    $t("appointmentForm.clientInput.details.notesLabel")
                  }}</label>
                  <span>{{ selectedClient.notes }}</span>
                </div>
              </div>
              <AppointmentsList
                :client="selectedClient"
                :paginationDisabled="true"
              />
            </div>
          </template>

          <template v-slot:optionItem="{ option }">
            <div class="badge-option-container">
              <div class="badge-option">
                <div class="bb-badge">{{ option.initials }}</div>
                <div class="badge-option-text">
                  <span>{{ option.text }}</span>
                  <small>{{ option.description }}</small>
                </div>
              </div>
            </div>
          </template>

          <template v-slot:optionsFooter>
            <div
              @click="createClient"
              v-if="
                $hasRole(acl.roles.owner) ||
                $hasPermission(acl.permissions.manageAccount)
              "
            >
              <i class="fas fa-plus"></i>
              <span>{{ $t("appointmentForm.clientInput.createBtn") }}</span>
            </div>
          </template>
        </BBSelect>
        <div 
          class="bb-invalid-feedback" 
          v-show="form.errors.clientId"
          v-if="!currentUser.isInLawIndustry"
        >
          {{ form.errors.clientId }}
        </div>
        <BBSelect
          v-if="currentUser.isInLawIndustry"
          :options="subjectsAsOptions"
          v-model="form.fields.subjectId"
          :search="true"
          :placeholder="$t('appointmentForm.subjectInput.placeholder')"
          :searchPlaceholder="
            $t('appointmentForm.subjectInput.searchPlaceholder')
          "
          :noOptionsText="$t('appointmentForm.subjectInput.noResultsText')"
        >
          <template v-if="selectedSubject" v-slot:toggle="{ removeSelected }">
            <div
              class="badge-option-container"
              @click="subjectDetailsVisible = !subjectDetailsVisible"
            >
              <div class="badge-option">
                <div class="bb-badge">{{ selectedSubject.subjectNumber }}</div>
                <div class="badge-option-text">
                  <span>{{ selectedSubject.courtName }}</span>
                  <small>{{ selectedSubject.courtNumber }}</small>
                </div>
              </div>
              <i :class="subjectDetailsVisible"></i>
            </div>
            <div class="selected-subject-details" v-show="subjectDetailsVisible">
              <div class="selected-subject-actions">
                <span
                  class="text-success mr-4"
                  @click="editSubject(selectedSubject)"
                  v-if="
                    $hasRole(acl.roles.owner) ||
                    $hasPermission(acl.permissions.manageAccount)
                  "
                  >{{ $t("appointmentForm.clientInput.editBtn") }}</span
                >
                <span class="text-danger" @click="removeSelected">{{
                  $t("appointmentForm.clientInput.removeBtn")
                }}</span>
              </div>
              <div class="selected-subject-info">
                <h5>{{ $t("appointmentForm.clientInput.details.title") }}</h5>
                <div class="bb-client-profile-info-item">
                  <label>{{
                    $t("appointmentForm.clientInput.details.emailLabel")
                  }}</label>
                  <span>{{ selectedSubject.client.email }}</span>
                </div>
                <div class="bb-client-profile-info-item">
                  <label>{{
                    $t("appointmentForm.clientInput.details.phoneLabel")
                  }}</label>
                  <span>{{ selectedSubject.client.phone }}</span>
                </div>
              </div>
            </div>
          </template>

          <template v-slot:optionItem="{ option }">
            <div class="badge-option-container">
              <div class="badge-option">
                <div class="bb-badge">{{ option.subjectNumber }}</div>
                <div class="badge-option-text">
                  <span>{{ option.text }}</span>
                  <small>{{ option.courtNumber }}</small>
                </div>
              </div>
            </div>
          </template>

          <template v-slot:optionsFooter>
            <div
              @click="createSubject"
              v-if="
                $hasRole(acl.roles.owner) ||
                $hasPermission(acl.permissions.manageAccount)
              "
            >
              <i class="fas fa-plus"></i>
              <span>{{ $t("appointmentForm.subjectInput.createBtn") }}</span>
            </div>
          </template>
        </BBSelect>
        <div 
          class="bb-invalid-feedback" 
          v-show="form.errors.subjectId"
          v-if="currentUser.isInLawIndustry"
        >
          {{ form.errors.subjectId }}
        </div>
      </div>
      <div class="mobile-client-select" v-if="isRegularAppointmentForm && !currentUser.isInLawIndustry">
        <div v-if="selectedClient">
          <div
            class="badge-option-container"
            @click="clientDetailsVisible = !clientDetailsVisible"
          >
            <div class="badge-option">
              <div class="bb-badge">{{ selectedClient.initials }}</div>
              <div class="badge-option-text">
                <span>{{ selectedClient.fullName }}</span>
                <small>{{ selectedClient.email }}</small>
              </div>
            </div>
            <i :class="clientDetailsArrowClass"></i>
          </div>
          <div class="selected-client-details" v-show="clientDetailsVisible">
            <div class="selected-client-actions">
              <span
                class="text-success mr-4"
                @click="editClient(selectedClient)"
                v-if="
                  $hasRole(acl.roles.owner) ||
                  $hasPermission(acl.permissions.manageAccount)
                "
                >{{ $t("appointmentForm.clientInput.editBtn") }}</span
              >
              <span class="text-danger" @click="removeSelectedClient">{{
                $t("appointmentForm.clientInput.removeBtn")
              }}</span>
            </div>
            <div class="selected-client-info">
              <h5>{{ $t("appointmentForm.clientInput.details.title") }}</h5>
              <div class="bb-client-profile-info-item">
                <label>{{
                  $t("appointmentForm.clientInput.details.emailLabel")
                }}</label>
                <span>{{ selectedClient.email }}</span>
              </div>
              <div class="bb-client-profile-info-item">
                <label>{{
                  $t("appointmentForm.clientInput.details.phoneLabel")
                }}</label>
                <span>{{ selectedClient.phone }}</span>
              </div>
              <div class="bb-client-profile-info-item">
                <label>{{
                  $t("appointmentForm.clientInput.details.notesLabel")
                }}</label>
                <span>{{ selectedClient.notes }}</span>
              </div>
            </div>
            <AppointmentsList
              :client="selectedClient"
              :paginationDisabled="true"
            />
          </div>
        </div>
        <div
          v-if="!selectedClient"
          class="mobile-client-select-btn"
          @click="selectClient"
        >
          <span>{{ $t("appointmentForm.clientInput.placeholder") }}</span>
          <i class="la la-angle-right"></i>
        </div>
      </div>
      <div class="mobile-subject-select" v-if="isRegularAppointmentForm">
        <div v-if="selectedSubject">
          <div
            class="badge-option-container"
              @click="subjectDetailsVisible = !subjectDetailsVisible"
          >
            <div class="badge-option">
              <div class="bb-badge">{{ selectedSubject.subjectNumber }}</div>
              <div class="badge-option-text">
                <span>{{ selectedSubject.courtName }}</span>
                <small>{{ selectedSubject.courtNumber }}</small>
              </div>
            </div>
            <i :class="subjectDetailsArrowClass"></i>
          </div>
          <div class="selected-subject-details" v-show="subjectDetailsVisible">
              <div class="selected-subject-actions">
                <span
                  class="text-success mr-4"
                  @click="editSubject(selectedSubject)"
                  v-if="
                    $hasRole(acl.roles.owner) ||
                    $hasPermission(acl.permissions.manageAccount)
                  "
                  >{{ $t("appointmentForm.clientInput.editBtn") }}</span
                >
                <span class="text-danger" @click="removeSelectedSubject">{{
                  $t("appointmentForm.clientInput.removeBtn")
                }}</span>
              </div>
              <div class="selected-subject-info">
                <h5>{{ $t("appointmentForm.clientInput.details.title") }}</h5>
                <div class="bb-client-profile-info-item">
                  <label>{{
                    $t("appointmentForm.clientInput.details.emailLabel")
                  }}</label>
                  <span>{{ selectedSubject.client.email }}</span>
                </div>
                <div class="bb-client-profile-info-item">
                  <label>{{
                    $t("appointmentForm.clientInput.details.phoneLabel")
                  }}</label>
                  <span>{{ selectedSubject.client.phone }}</span>
                </div>
              </div>
            </div>
        </div>
        <div
          v-if="!selectedSubject"
          class="mobile-subject-select-btn"
          @click="selectSubject"
        >
          <span>{{ $t("appointmentForm.subjectInput.placeholder") }}</span>
          <i class="la la-angle-right"></i>
        </div>
      </div>
      <div v-if="isGroupAppointmentForm">
        <button type="button" class="multiple-client-select-btn" @click="selectClients()">
          <span>{{ clientsInputBtnLabel }}</span>
            <i class="la la-angle-right"></i>
        </button>
        <div class="bb-invalid-feedback" v-show="form.errors.clientIds">
          {{ form.errors.clientIds }}
        </div>
      </div>
      <hr />
      <div class="bb-date-input-value-container" @click="selectDate">
        <span class="bb-date-input-value">{{ selectedDateString }}</span>
        <i class="la la-angle-right"></i>
      </div>
      <div class="bb-invalid-feedback" v-show="form.errors.date">
        {{ form.errors.date }}
      </div>
      <hr />
      <div class="bb-form-group service-select">
        <label>{{ $t("appointmentForm.serviceInput.label") }}</label>
        <BBSelect
          :options="serviceOptions"
          v-model="form.fields.serviceId"
          :search="true"
          :placeholder="$t('appointmentForm.serviceInput.placeholder')"
          :searchPlaceholder="
            $t('appointmentForm.serviceInput.searchPlaceholder')
          "
          class="service-select"
          :noOptionsText="$t('appointmentForm.serviceInput.noResultsText')"
        >
          <template
            v-slot:optionGroupItem="{ option }"
            style="padding: 10px 13px"
          >
            <div class="service-option-container">
              <div class="service-option-text">
                <span>{{ option.text }}</span>
                <small>{{ option.description }}</small>
              </div>
              <span>{{ option.price }}</span>
            </div>
          </template>
          <template v-slot:optionsFooter>
            <div
              @click="createService({ placeId: currentPlace.id })"
              v-if="
                $hasRole(acl.roles.owner) ||
                $hasPermission(acl.permissions.manageAccount)
              "
            >
              <i class="fas fa-plus"></i>
              <span>{{ $t("appointmentForm.serviceInput.createBtn") }}</span>
            </div>
          </template>
        </BBSelect>
        <div class="bb-invalid-feedback" v-show="form.errors.serviceId">
          {{ form.errors.serviceId }}
        </div>
      </div>
      <div class="mobile-service-select">
        <div class="bb-form-group">
          <label>{{ $t("appointmentForm.serviceInput.label") }}</label>
          <div class="mobile-service-select-btn" @click="selectService">
            <span class="mobile-service-placeholder" v-if="!selectedService">{{
              $t("appointmentForm.serviceInput.placeholder")
            }}</span>
            <span class="mobile-service-selected" v-if="selectedService">{{
              selectedService.name
            }}</span>
            <i class="la la-angle-right"></i>
          </div>
        </div>
      </div>
      <div class="bb-form-group">
        <label>{{ $t("appointmentForm.staffInput.label") }}</label>
        <BBSelect
          :options="staffOptions"
          v-model="form.fields.calendarId"
          :placeholder="$t('appointmentForm.staffInput.placeholder')"
          :search="true"
          :searchPlaceholder="
            $t('appointmentForm.staffInput.searchPlaceholder')
          "
          :noOptionsText="$t('appointmentForm.staffInput.noResultsText')"
        >
          <template v-slot:optionItem="{ option }">
            <div class="badge-option-container">
              <div class="badge-option">
                <div
                  class="bb-badge"
                  :style="'background-color:' + option.color"
                  style="flex: 0 0 auto"
                >
                  {{ option.initials }}
                </div>
                <div class="badge-option-text">
                  <span>{{ option.text }}</span>
                  <small>{{ option.description }}</small>
                </div>
              </div>
            </div>
          </template>
          <template v-slot:optionsFooter>
            <div
              @click="createCalendar"
              v-if="
                $hasRole(acl.roles.owner) ||
                $hasPermission(acl.permissions.manageAccount) ||
                $hasPermission(acl.permissions.managePlace)
              "
            >
              <i class="fas fa-plus"></i>
              <span>{{ $t("appointmentForm.staffInput.createBtn") }}</span>
            </div>
          </template>
        </BBSelect>
        <div class="bb-invalid-feedback" v-show="form.errors.calendarId">
          {{ form.errors.calendarId }}
        </div>
      </div>
      <div class="d-flex justify-content-between pt-5">
        <div class="bb-form-group mr-2">
          <label>{{ $t("appointmentForm.startTimeInput.label") }}</label>
          <BBSelect
            :options="startTimeOptions"
            :placeholder="$t('appointmentForm.startTimeInput.placeholder')"
            v-model="form.fields.from"
            :noOptionsText="noStartTimeOptionsText"
          ></BBSelect>
          <div class="bb-invalid-feedback" v-show="form.errors.from">
            {{ form.errors.from }}
          </div>
        </div>
        <div class="bb-form-group ml-2">
          <label>{{ $t("appointmentForm.durationLabel") }}</label>
          <div class="appointment-duration-value-disabled" v-if="!currentUser.isInLawIndustry">
            <span class="">{{ appointmentDuration }}</span>
            <i class="la la-angle-down"></i>
          </div>
          <div class="appointment-duration-value" v-else>
            <input class="bb-input" type="text" v-model="form.fields.duration" />
          </div>
        </div>
      </div>
      <div class="bb-form-group">
        <label>{{ $t("appointmentForm.notesInput.label") }}</label>
        <b-form-textarea
          :placeholder="$t('appointmentForm.notesInput.placeholder')"
          rows="4"
          class="bb-textarea"
          v-model="form.fields.notes"
        ></b-form-textarea>
        <div class="bb-invalid-feedback" v-show="form.errors.notes">
          {{ form.errors.notes }}
        </div>
      </div>
    </perfect-scrollbar>
    <!--end::Content-->
    <div class="offcanvas-footer">
      <button class="bb-btn btn-success" @click="submit()">
        {{ submitButtonText }}
      </button>
    </div>
    <ServiceFormModal ref="serviceFormModal" />
    <CalendarFormModal ref="calendarFormModal" />
    <ClientFormModal ref="clientFormModal" />
    <SubjectFormModal ref="subjectFormModal" />
  </div>
</template>

<script>
import AppointmentFormLayout from "@/assets/js/layout/extended/appointment-form.js";
import {
  OPEN_ELEMENT,
  CLOSE_ELEMENT,
} from "@/core/services/store/offcanvas.module.js";
import BBSelect from "@/view/content/BBForm/BBSelect.vue";
import { GET_CLIENTS } from "@/core/services/store/clients.module.js";
import { GET_SERVICES } from "@/core/services/store/services.module.js";
import { GET_SUBJECTS } from "@/core/services/store/subjects.module.js";
import { mapGetters } from "vuex";
import AppointmentsList from "@/view/pages/components/Client/AppointmentsList.vue";
import moment from "moment";
import serviceCategoryMapper from "@/core/services/helpers/serviceCategoryMapper.js";
import staffMapper from "@/core/services/helpers/staffMapper.js";
//import clientFormMixin from "@/core/mixins/clientFormMixin.js";
import clientFormModalMixin from "@/core/mixins/clientFormModalMixin.js";
//import serviceFormMixin from "@/core/mixins/serviceFormMixin.js";
import serviceFormModalMixin from "@/core/mixins/serviceFormModalMixin.js";
//import calendarFormMixin from "@/core/mixins/calendarFormMixin.js";
import calendarFormModalMixin from "@/core/mixins/calendarFormModalMixin.js";
import subjectFormModalMixin from "@/core/mixins/subjectFormModalMixin.js";
import appointmentForm from "@/core/services/forms/appointmentForm.js";
import formMixin from "@/core/mixins/formMixin.js";
import { SET_ALERT_MESSAGES } from "@/core/services/store/alert.module.js";
import { GET_PLACES } from "@/core/services/store/places.module.js";
import httpService from "@/core/services/http.service.js";
import { enableBodyScroll } from "body-scroll-lock";
import timeOptionsMapper from "@/core/services/helpers/timeOptionsMapper.js";
import i18nService from "@/core/services/i18n.service.js";
import ServiceFormModal from "@/view/layout/extras/modal/ServiceFormModal.vue";
import CalendarFormModal from "@/view/layout/extras/modal/CalendarFormModal.vue";
import ClientFormModal from "@/view/layout/extras/modal/ClientFormModal.vue";;
import SubjectFormModal from "@/view/layout/extras/modal/SubjectFormModal.vue"

export default {
  name: "AppointmentForm",
  components: {
    BBSelect,
    AppointmentsList,
    ServiceFormModal,
    CalendarFormModal,
    ClientFormModal,
    SubjectFormModal,
  },
  mixins: [
    formMixin,
    clientFormModalMixin,
    serviceFormModalMixin,
    subjectFormModalMixin,
    calendarFormModalMixin,
  ],
  data() {
    return {
      clientDetailsVisible: false,
      subjectDetailsVisible: false,
      startTimeOptions: [],
    };
  },
  beforeMount() {
    // Get form object from appointmentForm service
    this.form = JSON.parse(JSON.stringify(appointmentForm));
  },
  mounted() {
    // Init Appointment Form Panel
    AppointmentFormLayout.init(this.$refs["appointment_form"]);
    // Dispatch GET_CLIENTS store action to get clients from api
    this.$store.dispatch(GET_CLIENTS);
    // Dispatch GET_SERVICES store action to get services from api
    this.$store.dispatch(GET_SERVICES);
    // Dispatch GET_SUBJECTS store action to get subjects from api
    this.$store.dispatch(GET_SUBJECTS);
  },
  computed: {
    ...mapGetters([
      "clientsAsOptions", 
      "currentPlace", 
      "currentUser", 
      "subjectsAsOptions"
    ]),

    /**
     * Return title for appointment form element
     * @return {String}
     */
    title() {
      if (this.form.mode === "edit") {
        return this.isGroupAppointmentForm ? 
          this.$t("appointmentForm.editGroupTitle") : 
          this.$t("appointmentForm.editTitle");
      }

      return this.isGroupAppointmentForm ? 
        this.$t("appointmentForm.createGroupTitle") :
        this.$t("appointmentForm.createTitle");
    },

    /**
     * Return submit button text
     * @return {String}
     */
    submitButtonText() {
      if (this.form.mode === "edit") {
        return this.$t("appointmentForm.editSubmitBtn");
      }

      return this.$t("appointmentForm.createSubmitBtn");
    },

    /**
     * Return selected client object
     * @return {Object|null}
     */
    selectedClient() {
      if (this.form.fields.clientId) {
        return this.$store.getters.getClient(this.form.fields.clientId);
      }
      return null;
    },

    /**
     * Return selected client object
     * @return {Object|null}
     */
     selectedSubject() {
      if (this.form.fields.subjectId) {
        return this.$store.getters.getSubject(this.form.fields.subjectId);
      }
      return null;
    },

    /**
     * Return selected service object
     * @return {Object|null}
     */
    selectedService() {
      if (this.form.fields && this.form.fields.serviceId) {
        let services = [];
        this.currentPlace.services.forEach((serviceCategory) => {
          serviceCategory.services.forEach((service) => {
            services.push(service);
          });
        });
        return services.find(
          (service) => service.id === this.form.fields.serviceId
        );
      }
      return null;
    },

    /**
     * Return selected staff member object
     * @return {Object|null}
     */
    selectedStaff() {
      if (this.form.fields && this.form.fields.calendarId) {
        return this.currentPlace.staff.find(
          (staffMember) =>
            staffMember.calendar.id === this.form.fields.calendarId
        );
      }
      return null;
    },

    /**
     * Return array of services as options for select
     * @return {Array}
     */
    serviceOptions() {
      let servicesExists = this.currentPlace && this.currentPlace.services ? true : false;
      let services = servicesExists ? this.currentPlace.services : [];
      if(servicesExists) {
        if(this.isRegularAppointmentForm) {
          services = this.currentPlace.services.map(function(serviceCategory) {
            let serviceCategoryCopy = JSON.parse(JSON.stringify(serviceCategory))
            serviceCategoryCopy.services = serviceCategoryCopy.services.filter(function(service) {
              return service.isGroupService == false
            })
            return serviceCategoryCopy.services.length > 0 ? serviceCategoryCopy : null
          }).filter(function(serviceCategory) {
            return serviceCategory !== null
          })
        }

        if(this.isGroupAppointmentForm) {
          services = this.currentPlace.services.map(function(serviceCategory) {
            let serviceCategoryCopy = JSON.parse(JSON.stringify(serviceCategory))
            serviceCategoryCopy.services = serviceCategoryCopy.services.filter(function(service) {
              return service.isGroupService == true
            })
            return serviceCategoryCopy.services.length > 0 ? serviceCategoryCopy : null
          }).filter(function(serviceCategory) {
            return serviceCategory !== null
          })
        }
      }
      
      return serviceCategoryMapper.mapServiceCategoriesAsOptions(services);
    },

    /**
     * Return array of services as options for select
     * @return {Array}
     */
    staffOptions() {
      if (this.currentPlace && this.currentPlace.staff) {
        let staffMembers = this.currentPlace.staff;
        if (
          this.$hasRole(this.acl.roles.staffMember) &&
          this.$hasOnlyBasePermission()
        ) {
          staffMembers = staffMembers.filter((staffMember) => {
            if (staffMember.id == this.currentUser.id) {
              return staffMember;
            }
          });
        }
        if (this.selectedService) {
          return staffMapper.mapStaffAsOptions(
            staffMembers.filter((staffMember) => {
              if (staffMember.servicesIds.includes(this.selectedService.id)) {
                return staffMember;
              }
            })
          );
        }
        return staffMapper.mapStaffAsOptions(staffMembers);
      }
      return [];
    },

    /**
     * Return arrow class name for client details
     * @return {String}
     */
    clientDetailsArrowClass() {
      return this.clientDetailsVisible ? "la la-angle-up" : "la la-angle-down";
    },

    /**
     * Return arrow class name for subject details
     * @return {String}
     */
     subjectDetailsArrowClass() {
      return this.subjectDetailsVisible ? "la la-angle-up" : "la la-angle-down";
    },

    /**
     * Return string for date field value
     * @return {String}
     */
    selectedDateString() {
      return this.form.fields.date
        ? moment(this.form.fields.date)
            .locale(i18nService.getActiveLanguage())
            .format("dddd, D MMMM YYYY")
        : this.$t("appointmentForm.noDateLabel");
    },

    /**
     * Present appointment duration based on selected service
     * @return {String}
     */
    appointmentDuration() {
      if (this.selectedService) {
        return this.selectedService.duration + " min";
      }
      return "";
    },

    /**
     * Return string for start time no available options
     * @return {String}
     */
    noStartTimeOptionsText() {
      if (this.selectedStaff && this.selectedService && this.form.fields.date) {
        return this.$t("appointmentForm.startTimeInput.noResultsText");
      }

      return "Please select date, service and staff to see available start time options";
    },

    /**
     * Determine if it is form for regular appointment
     * @return {Boolean}
     */
    isRegularAppointmentForm() {
      return this.form.fields.type === "REGULAR"
    },

    /**
     * Determine if it is form for group appointment
     * @return {Boolean}
     */
     isGroupAppointmentForm() {
      return this.form.fields.type === "GROUP"
    },

    /**
     * Return clients input button label
     * @return {String}
     */
    clientsInputBtnLabel() {
      let countString = this.selectedService ?
        `${this.form.fields.clientIds.length}/${this.selectedService.sameTimePeople}` :
        this.form.fields.clientIds.length;
      return `${this.$t("appointmentForm.clientsInputBtn")} (${countString})`
    }
  },
  methods: {
    /**
     * Handle outside click
     * @return {void}
     */
    handleOutsideClick(event) {
      if (event.target.classList.contains("offcanvas-overlay")) {
        this.closeForm();
      }
    },

    /**
     * Dispach CLOSE_ELEMENT action from offcanvas module
     * @return {void}
     */
    closeOffcanvas(elementId) {
      this.$store.dispatch(CLOSE_ELEMENT, elementId);
      let targetElement = document.querySelector("#appointment_form");
      enableBodyScroll(targetElement);
    },

    /**
     * Open offcanvas element by given offcanvas element id
     * @param {String} elementId
     * @return {void}
     */
    openOffcanvas(elementId) {
      document.querySelector("#" + elementId + "_toggle").click();
      this.$store.dispatch(OPEN_ELEMENT, elementId);
    },

    /**
     * Close the Appointment form by imitating click on #appointment_form_close element
     * @return {void}
     */
    closeForm() {
      document.querySelector("#appointment_form_close").click();
    },

    /**
     * Open DateInput element and add form key attribute to element
     * @return {void}
     */
    selectDate() {
      document
        .querySelector("#date_input")
        .setAttribute("form-key", "appointmentForm");
      this.openOffcanvas("date_input");
    },

    /**
     * Open MobileClientSelect element and add form key attribute to element
     * @return {void}
     */
    selectClient() {
      document
        .querySelector("#mobile_client_select")
        .setAttribute("form-key", "appointmentForm");
      this.openOffcanvas("mobile_client_select");
    },

    /**
     * Open MobileSubjectSelect element and add form key attribute to element
     * @return {void}
     */
     selectSubject() {
      document
        .querySelector("#mobile_subject_select")
        .setAttribute("form-key", "appointmentForm");
      this.openOffcanvas("mobile_subject_select");
    },

    /**
     * Open MultipleCLientSelect element and add form key attribute to element
     * @return {void}
     */
    selectClients() {
      document
        .querySelector("#multiple_client_select")
        .setAttribute("form-key", "appointmentForm");
      this.openOffcanvas("multiple_client_select");
    },

    /**
     * Open MobileServiceSelect element and add form key attribute to element
     * @return {void}
     */
    selectService() {
      document
        .querySelector("#mobile_service_select")
        .setAttribute("form-key", "appointmentForm");
      this.openOffcanvas("mobile_service_select");
    },

    /**
     * Update startTimeOptions
     * @return {void}
     */
    updateStartTimeOptions() {
      let queryParams = {
        calendar_id: this.selectedStaff.calendar.id,
        service_id: this.selectedService.id,
        date: moment(this.form.fields.date).format("YYYY-MM-DD"),
        space: 5,
      };
      if (this.form.helpers.appointmentId) {
        queryParams.appointment_id = this.form.helpers.appointmentId;
      }
      httpService
        .get("/api/b2b/free-slots", { params: queryParams })
        .then((response) => {
          this.startTimeOptions = [];
          response.data.data.forEach((slot) => {
            this.startTimeOptions.push({
              text: slot.slice(0, -3),
              value: slot.slice(0, -3),
            });
          });
        });
    },

    /**
     * Update appointment duration
     * @return {void}
     */
    updateAppointmentDuration() {
      if (this.selectedService && !this.form.fields.duration) {
        this.form.fields.duration = this.selectedService.duration;
      }
    },  

    /**
     * Submit the form
     * @return {void}
     */
    submit() {
      this.submitForm()
        .then((response) => {
          this.$store.dispatch(SET_ALERT_MESSAGES, {
            successMessages: [response.data.message],
            errorMessages: null,
          });
          this.closeForm();
          this.$store.dispatch(GET_PLACES);
        })
        .catch((error) => {
          this.$store.dispatch(SET_ALERT_MESSAGES, {
            successMessages: null,
            errorMessages: [error.response.data.message],
          });
        });
    },

    /**
     * Remove selected client
     * @return {void}
     */
    removeSelectedClient() {
      this.form.fields.clientId = null;
    },

    /**
     * Remove selected subject
     * @return {void}
     */
     removeSelectedSubject() {
      this.form.fields.subjectId = null;
    },
  },

  watch: {
    /**
     * Watch for selectedStaff change
     */
    selectedStaff: {
      handler() {
        if (
          this.selectedStaff &&
          this.selectedService &&
          this.form.fields.date
        ) {
          this.updateStartTimeOptions();
        }
      },
    },
    /**
     * Watch for selectedService change
     */
    selectedService: {
      handler() {
        if (
          this.selectedStaff &&
          this.selectedService &&
          this.form.fields.date
        ) {
          this.updateStartTimeOptions();
        }

        this.updateAppointmentDuration();
      },
    },
    /**
     * Watch for date field change
     */
    "form.fields.date": {
      handler() {
        if (this.currentPlace && this.form.fields.date) {
          this.startTimeOptions = timeOptionsMapper.mapTimeOptions(
            moment(this.form.fields.date).locale("en").format("dddd"),
            this.currentPlace
          );
        }

        if (
          this.selectedStaff &&
          this.selectedService &&
          this.form.fields.date
        ) {
          this.updateStartTimeOptions();
        }
      },
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/sass/bb-styles/forms/appointment-form.scss";
</style>

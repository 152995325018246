const blockTimeForm = {
  /**
   * Block time form key
   * @type {String}
   */
  key: "blockTimeForm",

  /**
   * Block time form fields object
   * @type {Object}
   */
  fields: {
    type: "BLOCK_TIME",
    date: null,
    calendarId: null,
    from: null,
    to: null,
    notes: null
  },

  /**
   * Block time form errors object
   * @type {Object}
   */
  errors: {},

  /**
   * Block time form mode
   * @type {String}
   */
  mode: "create",

  /**
   * Block time form path
   * @type {String}
   */
  path: "/api/b2b/appointments",

  /**
   * Block time form helpers
   * @type {Object}
   */
  helpers: {}
};

export default blockTimeForm;

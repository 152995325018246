<template>
  <div
    id="availability_input"
    ref="availability_input"
    class="offcanvas offcanvas-right offcanvas-xl"
    v-click-outside="handleOutsideClick"
  >
    <button id="availability_input_toggle" style="display: none;"></button>
    <!--begin::Header-->
    <div
      class="offcanvas-header d-flex align-items-center justify-content-between pb-5"
    >
      <a
        href="#"
        class=""
        id="availability_input_close"
        @click="closeOffcanvas('availability_input')"
      >
        <i class="fas fa-arrow-left"></i>
      </a>
      <h4 class="font-weight-bold m-0">
        {{ $t("availabilityInput.title") }}
      </h4>
      <div></div>
    </div>
    <!--end::Header-->
    <!--begin::Content-->
    <perfect-scrollbar
      class="offcanvas-content mr-n5 scroll"
      style="max-height: 85vh; position: relative;"
    >
      <div
        class="daily-working-hours"
        :class="key === 'sunday' ? 'mb-14' : ''"
        v-for="(dailyWorkingHours, key) in workingHours"
        :key="key"
      >
        <div class="daily-working-hours-switch">
          <BBSwitch
            :label="$t('weekdays.' + key)"
            v-model="dailyWorkingHours.on"
          />
          <span
            v-show="!dailyWorkingHours.on"
            class="mobile-daily-working-hours-closed"
            >{{ $t("availabilityInput.closedLabel") }}</span
          >
        </div>
        <div v-show="dailyWorkingHours.on" class="daily-working-hours-selects">
          <div
            class="daily-working-hours-select"
            v-for="(time, index) in dailyWorkingHours.hours"
            :key="index"
          >
            <BBSelect
              :options="getTimeOptions(key)"
              :placeholderHidden="true"
              v-model="time.from"
            ></BBSelect>
            <span> - </span>
            <BBSelect
              :options="getTimeOptions(key, time.from)"
              :placeholderHidden="true"
              v-model="time.to"
            ></BBSelect>
            <div v-if="index === 0" style="width: 22px;"></div>
            <button
              class="daily-working-hours-remove-btn"
              v-if="index !== 0"
              @click="removeHoursForDay(key, index)"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#99ACC2"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-x"
              >
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </button>
          </div>
          <span
            class="daily-working-hours-add-btn"
            @click="addHoursForDay(key)"
            >{{ $t("availabilityInput.addBtn") }}</span
          >
        </div>
        <span
          v-show="!dailyWorkingHours.on"
          class="daily-working-hours-closed"
          >{{ $t("availabilityInput.closedLabel") }}</span
        >
      </div>
    </perfect-scrollbar>
    <!--end::Content-->
    <div class="offcanvas-footer">
      <button class="bb-btn btn-success" @click="save">
        {{ $t("availabilityInput.saveBtn") }}
      </button>
    </div>
  </div>
</template>

<script>
import AvailabilityInputLayout from "@/assets/js/layout/extended/availability-input.js";
import { CLOSE_ELEMENT } from "@/core/services/store/offcanvas.module.js";
import { mapGetters } from "vuex";
import BBSwitch from "@/view/content/BBForm/BBSwitch.vue";
import BBSelect from "@/view/content/BBForm/BBSelect.vue";
import { SET_FORM_FIELD } from "@/core/services/store/form.module.js";

export default {
  name: "AvailabilityInput",
  components: { BBSwitch, BBSelect },
  data() {
    return {
      formKey: null,
      selectedPlace: null,
      minTime: 6,
      maxTime: 24,
      workingHours: {
        monday: {
          on: true,
          hours: [
            {
              from: "06:00",
              to: "23:55"
            }
          ]
        },
        tuesday: {
          on: true,
          hours: [
            {
              from: "06:00",
              to: "23:55"
            }
          ]
        },
        wednesday: {
          on: true,
          hours: [
            {
              from: "06:00",
              to: "23:55"
            }
          ]
        },
        thursday: {
          on: true,
          hours: [
            {
              from: "06:00",
              to: "23:55"
            }
          ]
        },
        friday: {
          on: true,
          hours: [
            {
              from: "06:00",
              to: "23:55"
            }
          ]
        },
        saturday: {
          on: false,
          hours: [
            {
              from: null,
              to: null
            }
          ]
        },
        sunday: {
          on: false,
          hours: [
            {
              from: null,
              to: null
            }
          ]
        }
      }
    };
  },
  mounted() {
    // Init Availability Input Panel
    AvailabilityInputLayout.init(this.$refs["availability_input"]);
  },

  methods: {
    /**
     * Handle outside click
     * @return {void}
     */
    handleOutsideClick(event) {
      if (event.target.classList.contains("offcanvas-overlay")) {
        document.querySelector("#availability_input_close").click();
      }
    },

    /**
     * Dispach CLOSE_ELEMENT action from offcanvas module
     * @return {void}
     */
    closeOffcanvas(elementId) {
      this.$store.dispatch(CLOSE_ELEMENT, elementId);
    },

    /**
     * Update workingHours data property based on formState working hours
     * @return {void}
     */
    updateWorkingHours() {
      if (this.formState && this.formState.fields.workingHours) {
        let workingHours = JSON.parse(
          JSON.stringify(this.formState.fields.workingHours)
        );
        for (const [key, value] of Object.entries(workingHours)) {
          if (value[0].from) {
            this.workingHours[key].on = true;
          } else {
            this.workingHours[key].on = false;
          }
          this.workingHours[key].hours = value;
        }
      }
    },

    /**
     * Set selectedPlace property in form has placeIdField
     * @return {void}
     */
    setSelectedPlace() {
      if (this.formState && this.formState.fields.placeId) {
        this.selectedPlace = this.$store.getters.getPlace(
          this.formState.fields.placeId
        );
      }
    },

    /**
     * Add new hours object to workingHours day
     * @param {String} dayName
     * @return {void}
     */
    addHoursForDay(dayName) {
      this.workingHours[dayName].hours.push({ from: null, to: null });
    },

    /**
     * Remove hours object for given day by given index
     * @param {String} dayName
     * @param {Number} index
     * @return {void}
     */
    removeHoursForDay(dayName, index) {
      this.workingHours[dayName].hours.splice(index, 1);
    },

    /**
     * Set working hours to form state
     * @return {void}
     */
    save() {
      let workingHours = {};
      for (const [key, value] of Object.entries(this.workingHours)) {
        workingHours[key] = value.hours;
      }
      if (this.formKey) {
        this.$store.dispatch(SET_FORM_FIELD, {
          formKey: this.formKey,
          fieldName: "workingHours",
          value: workingHours
        });
      }
      if (
        document.querySelector("#availability_input").hasAttribute("form-key")
      ) {
        document
          .querySelector("#availability_input")
          .removeAttribute("form-key");
      }
      document.querySelector("#availability_input_close").click();
    },

    /**
     * Return array of time options
     * @param {String} dayName
     * @param {String|null} startTime
     * @return {Array}
     */
    getTimeOptions(dayName, startTime = null) {
      let options = [];
      let minTime = this.minTime;
      let maxTime = this.maxTime;
      if (this.selectedPlace) {
        if (this.selectedPlace.openingHours[dayName].from) {
          let minTimeArray = this.selectedPlace.openingHours[
            dayName
          ].from.split(":");
          minTime = parseInt(minTimeArray[0]);
          let maxTimeArray = this.selectedPlace.openingHours[dayName].to.split(
            ":"
          );
          maxTime = parseInt(maxTimeArray[0]);
        } else {
          return options;
        }
      }
      if (startTime) {
        let startTimeArray = startTime.split(":");
        let startTimeHour = parseInt(startTimeArray[0]);
        if (parseInt(startTimeArray[1]) > 0) {
          startTimeHour++;
        }
        minTime = startTimeHour;
      }
      for (let i = minTime; i <= maxTime; i++) {
        let maxMins = maxTime === i ? 5 : 60;
        for (let j = 0; j < maxMins; j += 5) {
          let timeSlot =
            String(i).padStart(2, "0") + ":" + String(j).padStart(2, "0");
          let option = {
            text: timeSlot,
            value: timeSlot
          };
          options.push(option);
        }
      }

      return options;
    }
  },
  computed: {
    ...mapGetters(["openedElements"]),

    /**
     * Get form from form module
     * @return {Object|null}
     */
    formState() {
      if (this.formKey) {
        return this.$store.getters.getForm(this.formKey);
      }
      return null;
    }
  },
  watch: {
    /**
     * Watch for opened elements change
     */
    openedElements() {
      if (this.openedElements.includes("availability_input")) {
        if (
          document.querySelector("#availability_input").hasAttribute("form-key")
        ) {
          this.formKey = document
            .querySelector("#availability_input")
            .getAttribute("form-key");
          this.updateWorkingHours();
          this.setSelectedPlace();
        }
      }
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/sass/bb-styles/scoped/availability-input.scss";
</style>

/**
 * Array of repeat options
 * @type {Array}
 */
const repeatOptions = [
  {
    text: "Doesn't repeat",
    value: "no_repeat"
  },
  {
    text: "Daily",
    value: "daily"
  },
  {
    text: "Every 2 days",
    value: "every_2_days"
  },
  {
    text: "Every 3 days",
    value: "every_3_days"
  },
  {
    text: "Every 4 days",
    value: "every_4_days"
  },
  {
    text: "Every 5 days",
    value: "every_5_days"
  },
  {
    text: "Every 6 days",
    value: "every_6_days"
  },
  {
    text: "Every 7 days",
    value: "every_7_days"
  },
  {
    text: "Weekly",
    value: "weekly"
  },
  {
    text: "Every 2 weeks",
    value: "every_2_weeks"
  },
  {
    text: "Every 3 weeks",
    value: "every_3_weeks"
  },
  {
    text: "Every 4 weeks",
    value: "every_4_weeks"
  },
  {
    text: "Every 5 weeks",
    value: "every_5_weeks"
  },
  {
    text: "Every 6 weeks",
    value: "every_6_weeks"
  },
  {
    text: "Every 7 weeks",
    value: "every_7_weeks"
  },
  {
    text: "Every 8 weeks",
    value: "every_8_weeks"
  },
  {
    text: "Every 9 weeks",
    value: "every_9_weeks"
  },
  {
    text: "Every 10 weeks",
    value: "every_10_weeks"
  },
  {
    text: "Monthly",
    value: "monthly"
  },
  {
    text: "Every 2 months",
    value: "every_2_months"
  },
  {
    text: "Every 3 months",
    value: "every_3_months"
  },
  {
    text: "Every 4 months",
    value: "every_4_months"
  },
  {
    text: "Every 5 months",
    value: "every_5_months"
  },
  {
    text: "Every 6 months",
    value: "every_6_months"
  },
  {
    text: "Every 7 months",
    value: "every_7_months"
  },
  {
    text: "Every 8 months",
    value: "every_8_months"
  },
  {
    text: "Every 9 months",
    value: "every_9_months"
  },
  {
    text: "Every 10 months",
    value: "every_10_months"
  },
  {
    text: "Every 11 months",
    value: "every_11_months"
  }
];

export default repeatOptions;

<template>
  <div
    id="block_time_form"
    ref="block_time_form"
    class="offcanvas offcanvas-right"
    v-click-outside="handleOutsideClick"
  >
    <button id="block_time_form_toggle" style="display: none;"></button>
    <!--begin::Header-->
    <div
      class="offcanvas-header d-flex align-items-center justify-content-between pb-5"
    >
      <h4 class="font-weight-bold m-0">
        {{ $t("blockTimeForm.title") }}
      </h4>
      <a
        href="#"
        class=""
        id="block_time_form_close"
        @click="closeOffcanvas('block_time_form')"
      >
        <i class="ki ki-close icon-md"></i>
      </a>
    </div>
    <!--end::Header-->
    <!--begin::Content-->
    <perfect-scrollbar
      class="offcanvas-content mr-n5 scroll"
      style="max-height: 85vh; position: relative;"
    >
      <div class="bb-date-input-value-container" @click="selectDate">
        <span
          class="bb-date-input-value"
          :class="this.form.fields.date ? '' : 'success'"
          >{{ selectedDateString }}</span
        >
        <i class="la la-angle-right"></i>
      </div>
      <div class="bb-invalid-feedback" v-show="form.errors.date">
        {{ form.errors.date }}
      </div>
      <hr />
      <div class="bb-form-group">
        <label>{{ $t("blockTimeForm.staffInput.label") }}</label>
        <BBSelect
          :options="staffOptions"
          v-model="form.fields.calendarId"
          :placeholder="$t('blockTimeForm.staffInput.placeholder')"
          :search="true"
          :searchPlaceholder="$t('blockTimeForm.staffInput.searchPlaceholder')"
        >
          <template v-slot:optionItem="{ option }">
            <div class="badge-option-container">
              <div class="badge-option">
                <div class="bb-badge">{{ option.initials }}</div>
                <div class="badge-option-text">
                  <span>{{ option.text }}</span>
                  <small>{{ option.description }}</small>
                </div>
              </div>
            </div>
          </template>
          <template v-slot:optionsFooter>
            <div
              @click="createCalendar"
              v-if="
                $hasRole(acl.roles.owner) ||
                  $hasPermission(acl.permissions.manageAccount) ||
                  $hasPermission(acl.permissions.managePlace)
              "
            >
              <i class="fas fa-plus"></i>
              <span>{{ $t("blockTimeForm.staffInput.createBtn") }}</span>
            </div>
          </template>
        </BBSelect>
        <div class="bb-invalid-feedback" v-show="form.errors.calendarId">
          {{ form.errors.calendarId }}
        </div>
      </div>
      <div class="d-flex justify-content-between pt-4">
        <div class="bb-form-group mr-2">
          <label>{{ $t("blockTimeForm.startTimeInput.label") }}</label>
          <BBSelect
            :options="startTimeOptions"
            :placeholder="$t('blockTimeForm.startTimeInput.placeholder')"
            v-model="form.fields.from"
            :noOptionsText="$t('blockTimeForm.startTimeInput.noResultsText')"
          ></BBSelect>
          <div class="bb-invalid-feedback" v-show="form.errors.from">
            {{ form.errors.from }}
          </div>
        </div>
        <div class="bb-form-group ml-2">
          <label>{{ $t("blockTimeForm.endTimeInput.label") }}</label>
          <BBSelect
            :options="endTimeOptions"
            :placeholder="$t('blockTimeForm.endTimeInput.placeholder')"
            v-model="form.fields.to"
            :noOptionsText="$t('blockTimeForm.endTimeInput.noResultsText')"
          ></BBSelect>
          <div class="bb-invalid-feedback" v-show="form.errors.to">
            {{ form.errors.to }}
          </div>
        </div>
      </div>
      <div class="bb-form-group">
        <label>{{ $t("blockTimeForm.notesInput.label") }}</label>
        <b-form-textarea
          :placeholder="$t('blockTimeForm.notesInput.placeholder')"
          rows="4"
          class="bb-textarea"
          v-model="form.fields.notes"
        ></b-form-textarea>
        <div class="bb-invalid-feedback" v-show="form.errors.notes">
          {{ form.errors.notes }}
        </div>
      </div>
    </perfect-scrollbar>
    <!--end::Content-->
    <div class="offcanvas-footer">
      <button class="bb-btn btn-success" @click="submit()">
        {{ submitButtonText }}
      </button>
    </div>
    <CalendarFormModal ref="calendarFormModal" />
  </div>
</template>

<script>
import BlockTimeFormLayout from "@/assets/js/layout/extended/block-time-form.js";
import {
  CLOSE_ELEMENT,
  OPEN_ELEMENT
} from "@/core/services/store/offcanvas.module.js";
import BBSelect from "@/view/content/BBForm/BBSelect.vue";
import moment from "moment";
import { mapGetters } from "vuex";
import staffMapper from "@/core/services/helpers/staffMapper.js";
// import calendarFormMixin from "@/core/mixins/calendarFormMixin.js";
import calendarFormModalMixin from "@/core/mixins/calendarFormModalMixin.js";
import blockTimeForm from "@/core/services/forms/blockTimeForm.js";
import formMixin from "@/core/mixins/formMixin.js";
import { SET_ALERT_MESSAGES } from "@/core/services/store/alert.module.js";
import { GET_PLACES } from "@/core/services/store/places.module.js";
import httpService from "@/core/services/http.service.js";
import { enableBodyScroll } from "body-scroll-lock";
import i18nService from "@/core/services/i18n.service.js";
import CalendarFormModal from "@/view/layout/extras/modal/CalendarFormModal.vue";

export default {
  name: "BlockTimeForm",
  components: { BBSelect, CalendarFormModal },
  mixins: [formMixin, calendarFormModalMixin],
  data() {
    return {
      startTimeOptions: []
    };
  },
  beforeMount() {
    // Get form object from blockTimeForm service
    this.form = JSON.parse(JSON.stringify(blockTimeForm));
  },
  mounted() {
    // Init Block Time Form Panel
    BlockTimeFormLayout.init(this.$refs["block_time_form"]);
  },
  methods: {
    /**
     * Handle outside click
     * @return {void}
     */
    handleOutsideClick(event) {
      if (event.target.classList.contains("offcanvas-overlay")) {
        this.closeForm();
      }
    },

    /**
     * Dispach CLOSE_ELEMENT action from offcanvas module
     * @return {void}
     */
    closeOffcanvas(elementId) {
      this.startTimeOptions = [];
      this.$store.dispatch(CLOSE_ELEMENT, elementId);
      let targetElement = document.querySelector("#block_time_form");
      enableBodyScroll(targetElement);
    },

    /**
     * Open offcanvas element by given offcanvas element id
     * @param {String} elementId
     * @return {void}
     */
    openOffcanvas(elementId) {
      document.querySelector("#" + elementId + "_toggle").click();
      this.$store.dispatch(OPEN_ELEMENT, elementId);
    },

    /**
     * Close the Appointment form by imitating click on #block_time_form_close element
     * @return {void}
     */
    closeForm() {
      document.querySelector("#block_time_form_close").click();
    },

    /**
     * Open DateInput element and add form key attribute to element
     * @return {void}
     */
    selectDate() {
      document
        .querySelector("#date_input")
        .setAttribute("form-key", "blockTimeForm");
      this.openOffcanvas("date_input");
    },

    /**
     * Submit the form
     * @return {void}
     */
    submit() {
      this.submitForm()
        .then(response => {
          this.$store.dispatch(SET_ALERT_MESSAGES, {
            successMessages: [response.data.message],
            errorMessages: null
          });
          this.closeForm();
          this.$store.dispatch(GET_PLACES);
        })
        .catch(error => {
          this.$store.dispatch(SET_ALERT_MESSAGES, {
            successMessages: null,
            errorMessages: [error.response.data.message]
          });
        });
    },

    /**
     * Update startTimeOptions
     * @return {void}
     */
    updateStartTimeOptions() {
      let queryParams = {
        type: "BLOCK_TIME",
        calendar_id: this.form.fields.calendarId,
        date: moment(this.form.fields.date).format("YYYY-MM-DD")
      };
      if (this.form.helpers.blockedTimeId) {
        queryParams.appointment_id = this.form.helpers.blockedTimeId;
      }
      httpService
        .get("/api/b2b/free-slots", { params: queryParams })
        .then(response => {
          this.startTimeOptions = [];
          response.data.data.forEach(time => {
            this.startTimeOptions.push({
              text: time.slice(0, -3),
              value: time.slice(0, -3)
            });
          });
        });
    }
  },
  computed: {
    ...mapGetters(["currentPlace", "currentUser"]),

    /**
     * Return submit button text
     * @return {String}
     */
    submitButtonText() {
      if (this.form.mode === "edit") {
        return this.$t("blockTimeForm.editSubmitBtn");
      }

      return this.$t("blockTimeForm.createSubmitBtn");
    },

    /**
     * Return string for date field value
     * @return {String}
     */
    selectedDateString() {
      return this.form.fields.date
        ? moment(this.form.fields.date)
            .locale(i18nService.getActiveLanguage())
            .format("dddd, D MMMM YYYY")
        : this.$t("blockTimeForm.dateLabel");
    },

    /**
     * Return array of services as options for select
     * @return {Array}
     */
    staffOptions() {
      if (this.currentPlace && this.currentPlace.staff) {
        if(this.$hasRole(this.acl.roles.owner)) {      
          return staffMapper.mapStaffAsOptions(this.currentPlace.staff);
        } else {
          let staff = [this.currentPlace.staff.find((item) => item.id === this.currentUser.id)]
          return staffMapper.mapStaffAsOptions(staff);
        }
      }
      return [];
    },

    /**
     * Return array of options for endTime field
     * @return {Array}
     */
    endTimeOptions() {
      if (this.startTimeOptions.length > 0 && this.form.fields.from) {
        let optionIndex = this.startTimeOptions.findIndex(
          option => option.value === this.form.fields.from
        );
        let endTimeOptions = JSON.parse(
          JSON.stringify(this.startTimeOptions)
        ).splice(optionIndex + 1);
        return endTimeOptions;
      }

      return [];
    }
  },

  watch: {
    /**
     * Watch for date form field
     */
    "form.fields.date": {
      handler() {
        if (this.form.fields.date && this.form.fields.calendarId) {
          this.updateStartTimeOptions();
        }
      }
    },
    /**
     * Watch for calendarId form field
     */
    "form.fields.calendarId": {
      handler() {
        if (this.form.fields.date && this.form.fields.calendarId) {
          this.updateStartTimeOptions();
        }
      }
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/sass/bb-styles/forms/block-time-form.scss";
</style>

<template>
    <div
      id="mobile_subject_select"
      ref="mobile_subject_select"
      class="offcanvas offcanvas-right"
    >
      <button id="mobile_subject_select_toggle" style="display: none;"></button>
      <!--begin::Header-->
      <div
        class="offcanvas-header d-flex align-items-center justify-content-between pb-5"
      >
        <h4 class="font-weight-bold m-0">
          {{ $t("mobileSubjectSelect.title") }}
        </h4>
        <a
          href="#"
          class=""
          id="mobile_subject_select_close"
          @click="closeOffcanvas('mobile_subject_select')"
        >
          <i class="ki ki-close icon-md"></i>
        </a>
      </div>
      <!--end::Header-->
      <!--begin::Content-->
      <perfect-scrollbar
        class="offcanvas-content mr-n5 scroll"
        style="max-height: 87vh; position: relative;"
      >
        <div class="bb-input-group search-input">
          <i class="fas fa-search"></i>
          <input
            type="text"
            v-model="searchTerm"
            :placeholder="$t('mobileSubjectSelect.searchPlaceholder')"
          />
          <button class="bb-badge badge-rounded" @click="searchTerm = ''">
            <i class="ki ki-close icon-md"></i>
          </button>
        </div>
        <div class="subjects-list">
          <div
            class="subject-item"
            v-for="subject in displaySubjects"
            :key="subject.id"
            @click="selectSubject(subject)"
          >
            <div class="d-flex justify-content-start align-items-center">
              <div class="bb-badge">
                {{ subject.subjectNumber }}
              </div>
              <div class="subject-details">
                <span>{{ subject.courtName }}</span>
                <small>{{ subject.courtNumber }}</small>
              </div>
            </div>
            <i class="la la-angle-right"></i>
          </div>
        </div>
      </perfect-scrollbar>
      <div class="offcanvas-footer">
        <button
          class="new-subject-btn"
          @click="createSubject"
          v-if="
            $hasRole(acl.roles.owner) ||
              $hasPermission(acl.permissions.manageAccount)
          "
        >
          <i class="la la-plus"></i>
        </button>
      </div>
      <SubjectFormModal ref="subjectFormModal" />
    </div>
  </template>
  
  <script>
  import { CLOSE_ELEMENT } from "@/core/services/store/offcanvas.module.js";
  import { SET_FORM_FIELD } from "@/core/services/store/form.module.js";
  import { mapGetters } from "vuex";
  import MobileSubjectSelectLayout from "@/assets/js/layout/extended/mobile-subject-select.js";
  import subjectFormModalMixin from "@/core/mixins/subjectFormModalMixin.js";
  import SubjectFormModal from "@/view/layout/extras/modal/SubjectFormModal.vue";
  
  export default {
    name: "MobileSubjectSelect",
    mixins: [subjectFormModalMixin],
    components: { SubjectFormModal },
    data() {
      return {
        formKey: null,
        searchTerm: ""
      };
    },
    mounted() {
      // Init Mobile select subject Panel
      MobileSubjectSelectLayout.init(this.$refs["mobile_subject_select"]);
    },
    methods: {
      /**
       * Dispach CLOSE_ELEMENT action from offcanvas module
       * @return {void}
       */
      closeOffcanvas(elementId) {
        this.$store.dispatch(CLOSE_ELEMENT, elementId);
      },
  
      /**
       * Close mobileSubjectSelect element by imitating click on mobile_subject_select_close element
       * @return {void}
       */
      close() {
        document.querySelector("#mobile_subject_select_close").click();
      },
  
      /**
       * Save subjectId value to form with this form key
       * @param {Object} subject
       * @return {void}
       */
      selectSubject(subject) {
        this.$store.dispatch(SET_FORM_FIELD, {
          formKey: this.formKey,
          fieldName: "subjectId",
          value: subject.id
        });
        if (
          document.querySelector("#mobile_subject_select").hasAttribute("form-key")
        ) {
          document
            .querySelector("#mobile_subject_select")
            .removeAttribute("form-key");
        }
        this.close();
      }
    },
    computed: {
      ...mapGetters(["openedElements", "allSubjects"]),
  
      /**
       * Return array of subjects to display
       * @return {Array}
       */
      displaySubjects() {
        if (this.allSubjects === 0) {
          return [];
        }
        return this.allSubjects.filter(subject =>
          subject.courtName.toLowerCase().includes(this.searchTerm.toLowerCase())
        );
      }
    },
    watch: {
      /**
       * Watch for opened elements change
       */
      openedElements() {
        if (this.openedElements.includes("mobile_subject_select")) {
          if (
            document
              .querySelector("#mobile_subject_select")
              .hasAttribute("form-key")
          ) {
            this.formKey = document
              .querySelector("#mobile_subject_select")
              .getAttribute("form-key");
          }
        }
      }
    }
  };
  </script>
  
  <style lang="scss">
  @import "@/assets/sass/bb-styles/components/mobile-subject-select.scss";
  </style>
  
<template>
  <div
    id="date_input"
    ref="date_input"
    class="offcanvas offcanvas-right"
    v-click-outside="handleOutsideClick"
  >
    <button id="date_input_toggle" style="display: none;"></button>
    <!--begin::Header-->
    <div
      class="offcanvas-header d-flex align-items-center justify-content-between pb-5"
    >
      <a
        href="#"
        class=""
        id="date_input_close"
        @click="closeOffcanvas('date_input')"
      >
        <i class="fas fa-arrow-left"></i>
      </a>
      <h4 class="font-weight-bold m-0 title">
        {{ title }}
      </h4>
      <h4 class="font-weight-bold m-0 mobile-title">
        {{ $t("dateInput.mobileTitle") }}
      </h4>
      <div></div>
    </div>
    <!--end::Header-->
    <!--begin::Content-->
    <perfect-scrollbar
      class="offcanvas-content mr-n5 scroll"
      style="max-height: 85vh; position: relative;"
    >
      <b-calendar
        ref="smallCalendar"
        :hide-header="true"
        today-variant="success"
        :locale="locale"
        @context="onContext"
        @selected="dateSelected"
        :date-disabled-fn="dateDisabled"
        :start-weekday="1"
        :value-as-date="true"
        v-model="selectedDate"
      >
        <template v-slot:nav-prev-month
          ><i class="la la-arrow-left b-calendar-custom-nav"></i
        ></template>
        <template v-slot:nav-next-month
          ><i class="la la-arrow-right b-calendar-custom-nav"></i
        ></template>
        <template v-slot:nav-this-month :disabled="disabled">
          <span class="b-calendar-month-string" style="margin-right:5px;">{{
            monthString
          }}</span>
          <span class="b-calendar-year-string">{{ yearString }}</span>
        </template>
      </b-calendar>
      <!-- <div class="bb-form-group">
        <label>Repeat</label>
        <BBSelect
          :options="repeatOptions"
          v-model="repeatValue"
          placeholder="No repeat"
        ></BBSelect>
      </div> -->
    </perfect-scrollbar>
    <!--end::Content-->
    <div class="offcanvas-footer">
      <button class="bb-btn btn-success save-btn" @click="save">
        {{ $t("dateInput.saveBtn") }}
      </button>
      <div class="mobile-calendar-navigation">
        <button @click="navigateSmallCalendar('prev')">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="16"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-arrow-left"
          >
            <line x1="19" y1="12" x2="5" y2="12"></line>
            <polyline points="12 19 5 12 12 5"></polyline>
          </svg>
        </button>
        <button @click="navigateSmallCalendar('next')">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="16"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-arrow-right"
          >
            <line x1="5" y1="12" x2="19" y2="12"></line>
            <polyline points="12 5 19 12 12 19"></polyline>
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import DateInputLayout from "@/assets/js/layout/extended/date-input.js";
import { CLOSE_ELEMENT } from "@/core/services/store/offcanvas.module.js";
//import BBSelect from "@/view/content/BBForm/BBSelect.vue";
import { mapGetters } from "vuex";
import moment from "moment";
import repeatOptions from "@/core/services/helpers/repeatOptions.js";
import { SET_FORM_FIELD } from "@/core/services/store/form.module.js";
import i18nService from "@/core/services/i18n.service.js";

export default {
  name: "DateInput",
  // components: { BBSelect },
  data() {
    return {
      disabledWeekDays: [],
      monthString: "",
      yearString: "",
      repeatValue: null,
      formKey: null,
      selectedDate: null,
      locale: i18nService.getActiveLanguage()
    };
  },
  mounted() {
    // Init Date Input Panel
    DateInputLayout.init(this.$refs["date_input"]);
  },
  methods: {
    /**
     * Handle outside click
     * @return {void}
     */
    handleOutsideClick(event) {
      if (event.target.classList.contains("offcanvas-overlay")) {
        document.querySelector("#date_input_close").click();
      }
    },

    /**
     * Dispach CLOSE_ELEMENT action from offcanvas module
     * @return {void}
     */
    closeOffcanvas(elementId) {
      this.$store.dispatch(CLOSE_ELEMENT, elementId);
    },

    /**
     * Determine if specific date needs to be disabled
     * @return {Boolean}
     */
    dateDisabled(ymd, date) {
      const weekday = date.getDay();
      return this.disabledWeekDays.includes(weekday);
    },

    /**
     * Map desabled week days for calendar based on current place
     * @return {void}
     */
    mapDisabledWeekDays() {
      this.disabledWeekDays = [];
      let openingHours = this.currentPlace.openingHours;
      Object.keys(openingHours).forEach(key => {
        switch (key.toLowerCase()) {
          case "monday":
            if (!openingHours[key].from) {
              this.disabledWeekDays.push(1);
            }
            break;
          case "tuesday":
            if (!openingHours[key].from) {
              this.disabledWeekDays.push(2);
            }
            break;
          case "wednesday":
            if (!openingHours[key].from) {
              this.disabledWeekDays.push(3);
            }
            break;
          case "thursday":
            if (!openingHours[key].from) {
              this.disabledWeekDays.push(4);
            }
            break;
          case "friday":
            if (!openingHours[key].from) {
              this.disabledWeekDays.push(5);
            }
            break;
          case "saturday":
            if (!openingHours[key].from) {
              this.disabledWeekDays.push(6);
            }
            break;
          case "sunday":
            if (!openingHours[key].from) {
              this.disabledWeekDays.push(0);
            }
            break;
        }
      });
    },

    /**
     * Update monthString and yearString data properties on small calendar context
     * @param {Object} ctx
     * @return {void}
     */
    onContext(ctx) {
      let activeDate = ctx.activeYMD;
      this.monthString = moment(activeDate)
        .locale(this.locale)
        .format("MMMM");
      this.yearString = moment(activeDate).format("YYYY");
    },

    /**
     * Handle b-calendar selected event
     * @return {void}
     */
    dateSelected() {
      this.save();
    },

    /**
     * Save date and repeat values to form with this form key and close date input element
     * @return {void}
     */
    save() {
      if (this.formKey) {
        this.$store.dispatch(SET_FORM_FIELD, {
          formKey: this.formKey,
          fieldName: "date",
          value: this.selectedDate
        });
        this.$store.dispatch(SET_FORM_FIELD, {
          formKey: this.formKey,
          fieldName: "repeat",
          value: this.repeatValue
        });
      }
      if (document.querySelector("#date_input").hasAttribute("form-key")) {
        document.querySelector("#date_input").removeAttribute("form-key");
      }
      document.querySelector("#date_input_close").click();
    },

    /**
     * Navigate small calendar
     * @param {String} direction
     * @return {void}
     */
    navigateSmallCalendar(direction) {
      if (direction === "next") {
        this.$refs.smallCalendar.gotoNextMonth();
      }

      if (direction === "prev") {
        this.$refs.smallCalendar.gotoPrevMonth();
      }
    }
  },
  computed: {
    ...mapGetters(["currentPlace", "openedElements"]),

    /**
     * Return array of repeat options for select
     * @return {Array}
     */
    repeatOptions() {
      return repeatOptions;
    },

    /**
     * Get form from form module
     * @return {Object|null}
     */
    formState() {
      if (this.formKey) {
        return this.$store.getters.getForm(this.formKey);
      }
      return null;
    },

    /**
     * Return title for date input element
     * @return {String}
     */
    title() {
      return this.formKey === "appointmentForm"
        ? this.$t("dateInput.appointmentTitle")
        : this.$t("dateInput.blockTimeTitle");
    }
  },
  watch: {
    /**
     * Watch for current place change to map disabled week days
     */
    currentPlace() {
      if (this.currentPlace) {
        this.mapDisabledWeekDays();
      }
    },

    /**
     * Watch for opened elements change
     */
    openedElements() {
      if (this.openedElements.includes("date_input")) {
        if (document.querySelector("#date_input").hasAttribute("form-key")) {
          this.formKey = document
            .querySelector("#date_input")
            .getAttribute("form-key");
          if (this.formState) {
            this.selectedDate = this.formState.fields.date;
            this.repeatValue = this.formState.fields.repeat;
          }
        }
      }
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/sass/bb-styles/components/date-input.scss";
</style>

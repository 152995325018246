<template>
    <b-modal id="delete_subject_modal" ref="bvModal">
      <template v-slot:modal-header>
        <div class="modal-header-buttons-container">
          <h4>{{ $t("deleteSubjectModal.title") }}</h4>
          <button class="close-modal-btn" @click="closeDeleteSubjectModal()">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-x"
            >
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </button>
        </div>
      </template>
      <p class="confirmation-message">
        {{ confirmationMessage }}
      </p>
      <template v-slot:modal-footer>
        <div class="modal-footer-buttons-container">
          <button
            class="ml-4 bb-btn btn-secondary"
            @click="closeDeleteSubjectModal()"
          >
            {{ $t("deleteSubjectModal.cancelBtn") }}
          </button>
          <button class="ml-4 bb-btn btn-danger" @click="deleteSubject()">
            {{ $t("deleteSubjectModal.confirmBtn") }}
          </button>
        </div>
      </template>
    </b-modal>
  </template>
  
  <script>
  import { SET_ALERT_MESSAGES } from "@/core/services/store/alert.module.js";
  import httpService from "@/core/services/http.service.js";
  import { EventBus } from "@/core/services/event-bus.js";
  
  export default {
    name: "DeleteSubjectModal",
    data() {
      return {
        subject: null,
        successCallback: null,
        errorCallback: null,
      };
    },
    methods: {
      /**
       * Reset component data
       * @return {void}
       */
      reset() {
        this.subject = null;
        this.successCallback = null;
        this.errorCallback = null;
      },
  
      /**
       * Close the modal
       * @return {void}
       */
      closeDeleteSubjectModal() {
        this.reset();
        this.$refs.bvModal.hide();
      },
  
      /**
       * Handle deletingSubject event
       * @param {Object} subject
       * @return {void}
       */
      handleDeletingSubject(event) {
        this.subject = event.subject;
        this.successCallback = event.successCallback;
        this.errorCallback = event.errorCallback;
        if (this.subject) {
          this.$refs.bvModal.show();
        }
      },
  
      /**
       * Send http request to delete subject
       * @return {void}
       */
      deleteSubject() {
        httpService
          .delete("/api/b2b/subjects/" + this.subject.id)
          .then((response) => {
            this.$store.dispatch(SET_ALERT_MESSAGES, {
              successMessages: [response.data.message],
              errorMessages: null,
            });
            if (this.successCallback) {
              this.successCallback();
            }
            this.closeDeleteSubjectModal();
          })
          .catch((error) => {
            this.$store.dispatch(SET_ALERT_MESSAGES, {
              successMessages: null,
              errorMessages: [error.response.data.message],
            });
            if (this.errorCallback) {
              this.errorCallback();
            }
            this.closeDeleteSubjectModal();
          });
      },
    },
    computed: {
      confirmationMessage() {
        return (
          this.$t("deleteSubjectModal.message") + " " + this.subject?.fullName + "?"
        );
      },
    },
    mounted() {
      EventBus.$on("deletingSubject", this.handleDeletingSubject);
    },
    destroyed() {
      EventBus.$off("deletingSubject", this.handleDeletingSubject);
    },
  };
  </script>
  <style lang="scss">
  @import "@/assets/sass/bb-styles/modals/delete-subject-modal.scss";
  </style>
  
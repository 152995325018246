<template>
  <b-modal id="delete_staff_modal" ref="bvModal">
    <template v-slot:modal-header>
      <div class="modal-header-buttons-container">
        <h4>{{ $t("deleteStaffModal.title") }}</h4>
        <button class="close-modal-btn" @click="closeDeleteStaffModal()">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-x"
          >
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
        </button>
      </div>
    </template>
    <p class="confirmation-message">
      {{ confirmationMessage }}
    </p>
    <template v-slot:modal-footer>
      <div class="modal-footer-buttons-container">
        <button
          class="ml-4 bb-btn btn-secondary"
          @click="closeDeleteStaffModal()"
        >
          {{ $t("deleteStaffModal.cancelBtn") }}
        </button>
        <button class="ml-4 bb-btn btn-danger" @click="deleteStaffMember()">
          {{ $t("deleteStaffModal.confirmBtn") }}
        </button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { SET_ALERT_MESSAGES } from "@/core/services/store/alert.module.js";
import httpService from "@/core/services/http.service.js";
import { EventBus } from "@/core/services/event-bus.js";

export default {
  name: "DeleteStaffModal",
  data() {
    return {
      staffMember: null,
      successCallback: null,
      errorCallback: null,
    };
  },
  methods: {
    /**
     * Reset component data
     * @return {void}
     */
    reset() {
      this.staffMember = null;
      this.successCallback = null;
      this.errorCallback = null;
    },

    /**
     * Close the modal
     * @return {void}
     */
    closeDeleteStaffModal() {
      this.reset();
      this.$refs.bvModal.hide();
    },

    /**
     * Handle deletingStaff event
     * @param {Object} client
     * @return {void}
     */
    handleDeletingStaff(event) {
      this.staffMember = event.staffMember;
      this.successCallback = event.successCallback;
      this.errorCallback = event.errorCallback;
      if (this.staffMember) {
        this.$refs.bvModal.show();
      }
    },

    /**
     * Send http request to delete client
     * @return {void}
     */
    deleteStaffMember() {
      httpService
        .delete("/api/b2b/calendars/" + this.staffMember.calendar?.id)
        .then((response) => {
          this.$store.dispatch(SET_ALERT_MESSAGES, {
            successMessages: [response.data.message],
            errorMessages: null,
          });
          if (this.successCallback) {
            this.successCallback();
          }
          this.closeDeleteStaffModal();
        })
        .catch((error) => {
          this.$store.dispatch(SET_ALERT_MESSAGES, {
            successMessages: null,
            errorMessages: [error.response.data.message],
          });
          if (this.errorCallback) {
            this.errorCallback();
          }
          this.closeDeleteStaffModal();
        });
    },
  },
  computed: {
    confirmationMessage() {
      return (
        this.$t("deleteStaffModal.message") +
        " " +
        this.staffMember?.fullName +
        "?"
      );
    },
  },
  mounted() {
    EventBus.$on("deletingStaff", this.handleDeletingStaff);
  },
  destroyed() {
    EventBus.$off("deletingStaff", this.handleDeletingStaff);
  },
};
</script>
<style lang="scss">
@import "@/assets/sass/bb-styles/modals/delete-staff-modal.scss";
</style>

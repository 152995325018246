import calendarForm from "@/core/services/forms/calendarForm.js";
import availabilityForm from "@/core/services/forms/availabilityForm.js";
import { INIT_FORM } from "@/core/services/store/form.module.js";
import _ from "lodash";

export default {
  methods: {
    /**
     * Init calendar form with default values from calendarForm object and open calendar form modal element
     * @param {Object|null} fields
     * @return {void}
     */
    createCalendar(fields = null) {
      let form = JSON.parse(JSON.stringify(calendarForm));
      if (fields) {
        for (const [key, value] of Object.entries(fields)) {
          form.fields[key] = value;
        }
      }
      this.$store.dispatch(INIT_FORM, form);
      if (this.$refs.calendarFormModal) {
        this.$refs.calendarFormModal.show();
      }
    },

    /**
     * Init calendar form with edit mode and open calendar form modal element
     * @param {Object} staffMember
     * @return {void}
     */
    editCalendar(staffMember) {
      let form = JSON.parse(JSON.stringify(calendarForm));
      form.mode = "edit";
      form.path = form.path + "/" + staffMember.calendar.id;
      form.helpers = this.updateFormHelpers(form.helpers, staffMember);
      form.fields = this.updateFormFields(form.fields, staffMember);
      form.fields._method = "PUT";
      this.$store.dispatch(INIT_FORM, form);
      if (this.$refs.calendarFormModal) {
        this.$refs.calendarFormModal.show();
      }
    },

    /**
     * Init availability form and open availability form modal element
     * @param {Object} calendar
     * @return {void}
     */
    editWorkingHours(calendar) {
      let form = JSON.parse(JSON.stringify(availabilityForm));
      form.mode = "edit";
      form.path = form.path + "/" + calendar.id;
      form.fields._method = "PUT";
      form.fields.workingHours = calendar.workingHours;
      form.helpers.workingHours = this.updateWorkingHoursHelper(calendar);
      this.$store.dispatch(INIT_FORM, form);
      if (this.$refs.availabilityFormModal) {
        this.$refs.availabilityFormModal.show();
      }
    },

    /**
     * Update form fields
     * @param {Object} formFields
     * @param {Object} staffMember
     * @return {Object}
     */
    updateFormFields(formFields, staffMember) {
      formFields.firstName = staffMember.firstName;
      formFields.lastName = staffMember.lastName;
      formFields.email = staffMember.email;
      formFields.phone = staffMember.phone;
      formFields.permissions = JSON.parse(
        JSON.stringify(staffMember.permissions)
      );
      formFields.serviceIds = JSON.parse(
        JSON.stringify(staffMember.servicesIds)
      );
      formFields.daysOff = JSON.parse(
        JSON.stringify(staffMember.calendar.daysOff)
      );
      formFields.clientProfileIds = JSON.parse(
        JSON.stringify(staffMember.clientProfileIds)
      );
      formFields.name = staffMember.calendar.name;
      formFields.title = staffMember.calendar.title;
      formFields.workingHours = staffMember.calendar.workingHours;
      formFields.placeId = staffMember.calendar.placeId;
      formFields.toc = staffMember.calendar.toc;
      formFields.bookingConfirmation =
        staffMember.calendar.settings.bookingConfirmation;
      formFields.cancellationConfirmation =
        staffMember.calendar.settings.cancellationConfirmation;
      formFields.spBookingConfirmation =
        staffMember.calendar.settings.spBookingConfirmation;
      formFields.spCancellationConfirmation =
        staffMember.calendar.settings.spCancellationConfirmation;
      formFields.bookingReminder =
        staffMember.calendar.settings.bookingReminder;
      formFields.followUp = staffMember.calendar.settings.followUp;
      formFields.followUpDays =
        staffMember.calendar.settings.followUpDays.toString();
      //new fields
      formFields.color = staffMember.calendar.color;
      formFields.placeIds = staffMember.placeIds;
      formFields.onlineBookings = staffMember.calendar.onlineBookings;
      formFields.employmentStartDate = staffMember.calendar.employmentStartDate;
      formFields.employmentEndDate = staffMember.calendar.employmentEndDate;

      return formFields;
    },

    /**
     * Update form helpers
     * @param {Object} formHelpers
     * @param {Object} staffMember
     * @return {Object}
     */
    updateFormHelpers(formHelpers, staffMember) {
      formHelpers.bookingMarginObject = this.generateMarginObject(
        staffMember.calendar.bookingMargin
      );
      formHelpers.cancellationMarginObject = this.generateMarginObject(
        staffMember.calendar.cancellationMargin
      );
      // formHelpers.requiredInfoObject = this.updateObjectFromArray(
      //   formHelpers.requiredInfoObject,
      //   staffMember.calendar.requiredInfo
      // );
      // formHelpers.permissionsObject = this.updateObjectFromArray(
      //   formHelpers.permissionsObject,
      //   staffMember.permissions
      // );
      formHelpers.avatar =
        staffMember.avatar && staffMember.avatar.url
          ? staffMember.avatar.url
          : null;
      formHelpers.calendarId = staffMember.calendar.id;
      formHelpers.permissionValue = this.getPermissionValue(staffMember);

      return formHelpers;
    },

    /**
     * Generate margin object for form helpers
     * @param {Number} minutes
     * @return {Object}
     */
    generateMarginObject(minutes) {
      let marginObject = {
        days: Math.floor(minutes / 60 / 24).toString(),
        hours: Math.floor((minutes / 60) % 24).toString(),
        minutes: (minutes % 60).toString(),
      };

      return marginObject;
    },

    /**
     * Update given object from array
     * @param {Object} object
     * @param {Array} array
     * @return {Object}
     */
    updateObjectFromArray(object, array) {
      for (let [key, value] of Object.entries(object)) {
        value = false;
        object[key] = value;
      }
      array.forEach((item) => {
        object[_.camelCase(item)] = true;
      });

      return object;
    },

    /**
     * Update working hours helper
     * @param {Object} calendar
     * @return {Object}
     */
    updateWorkingHoursHelper(calendar) {
      let workingHours = {};
      for (let [key, value] of Object.entries(calendar.workingHours)) {
        if (value.length > 0 && value[0].from) {
          workingHours[key] = {
            on: true,
            hours: value,
          };
        } else {
          workingHours[key] = {
            on: false,
            hours: value,
          };
        }
      }

      return workingHours;
    },

    /**
     * Return permission value for form helper
     * @param {Object} staffMember
     * @return {Number}
     */
    getPermissionValue(staffMember) {
      if (staffMember.permissions && staffMember.permissions.length > 0) {
        if (staffMember.permissions.includes("manage account")) {
          return 3;
        }

        if (staffMember.permissions.includes("manage place")) {
          return 2;
        }

        if (staffMember.permissions.includes("manage bookings")) {
          return 1;
        }
      }

      return 0;
    },
  },
};

const availabilityForm = {
  /**
   * Availability form key
   * @type {String}
   */
  key: "availabilityForm",

  /**
   * Availability form fields object
   * @type {Object}
   */
  fields: {
    workingHours: {
      monday: [
        {
          from: "06:00",
          to: "23:55"
        }
      ],
      tuesday: [
        {
          from: "06:00",
          to: "23:55"
        }
      ],
      wednesday: [
        {
          from: "06:00",
          to: "23:55"
        }
      ],
      thursday: [
        {
          from: "06:00",
          to: "23:55"
        }
      ],
      friday: [
        {
          from: "06:00",
          to: "23:55"
        }
      ],
      saturday: [
        {
          from: null,
          to: null
        }
      ],
      sunday: [
        {
          from: null,
          to: null
        }
      ]
    }
  },

  /**
   * Availability form errors object
   * @type {Object}
   */
  errors: {},

  /**
   * Availability form helpers object
   * @type {Object}
   */
  helpers: {
    workingHours: {
      monday: {
        on: true,
        hours: [
          {
            from: "06:00",
            to: "23:55"
          }
        ]
      },
      tuesday: {
        on: true,
        hours: [
          {
            from: "06:00",
            to: "23:55"
          }
        ]
      },
      wednesday: {
        on: true,
        hours: [
          {
            from: "06:00",
            to: "23:55"
          }
        ]
      },
      thursday: {
        on: true,
        hours: [
          {
            from: "06:00",
            to: "23:55"
          }
        ]
      },
      friday: {
        on: true,
        hours: [
          {
            from: "06:00",
            to: "23:55"
          }
        ]
      },
      saturday: {
        on: false,
        hours: [
          {
            from: null,
            to: null
          }
        ]
      },
      sunday: {
        on: false,
        hours: [
          {
            from: null,
            to: null
          }
        ]
      }
    }
  },

  /**
   * Availability form mode
   * @type {String}
   */
  mode: "create",

  /**
   * Availability form path
   * @type {String}
   */
  path: "/api/b2b/calendars/working-hours"
};

export default availabilityForm;

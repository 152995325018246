<template>
  <!-- begin:: Aside -->
  <div class="brand flex-column-auto" id="kt_brand" ref="kt_brand">
    <div class="brand-logo">
      <router-link to="/">
        <img :src="logoPath" alt="Logo" />
      </router-link>
    </div>
    <div class="brand-tools" v-if="allowMinimize">
      <button
        class="toggle-menu-btn"
        id="kt_aside_toggle"
        ref="kt_aside_toggle"
        @click="onToggle()"
      >
        <i v-bind:class="toggleIconClass"></i>
      </button>
    </div>
  </div>
  <!-- end:: Aside -->
</template>

<style lang="scss" scoped>
@import "@/assets/sass/bb-styles/scoped/menu-brand.scss";
</style>

<script>
import { mapGetters } from "vuex";
import objectPath from "object-path";
import KTLayoutBrand from "@/assets/js/layout/base/brand.js";
import KTLayoutAsideToggle from "@/assets/js/layout/base/aside-toggle.js";

export default {
  name: "KTBrand",
  data() {
    return {
      toggleIconClass: "fas fa-arrow-left"
    };
  },
  mounted() {
    // Init Brand Panel For Logo
    KTLayoutBrand.init(this.$refs["kt_brand"]);

    // Init Aside Menu Toggle
    KTLayoutAsideToggle.init(this.$refs["kt_aside_toggle"]);
  },
  methods: {
    siteLogo() {
      const menuAsideLeftSkin = this.layoutConfig("brand.self.theme");
      // set brand logo
      const logoObject = this.layoutConfig("self.logo");

      let logo;
      if (typeof logoObject === "string") {
        logo = logoObject;
      }
      if (typeof logoObject === "object") {
        logo = objectPath.get(logoObject, menuAsideLeftSkin + "");
      }
      if (typeof logo === "undefined") {
        const logos = this.layoutConfig("self.logo");
        logo = logos[Object.keys(logos)[0]];
      }
      return process.env.BASE_URL + logo;
    },
    onToggle() {
      window.dispatchEvent(new Event("resize"));
      if (KTLayoutAsideToggle.getToggle().state == "on") {
        this.toggleIconClass = "fas fa-arrow-right";
      } else if (KTLayoutAsideToggle.getToggle().state == "off") {
        this.toggleIconClass = "fas fa-arrow-left";
      }
    }
  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    allowMinimize() {
      return !!this.layoutConfig("aside.self.minimize.toggle");
    },
    logoPath() {
      return process.env.BASE_URL + "media/logos/bb_logo_negative.png";
    }
  }
};
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"subheader py-2 py-lg-4",attrs:{"id":"settings_subheader"}},[_c('div',{staticClass:"container-fluid d-flex align-items-center subheader-navigation"},[(
        _vm.$hasRole(_vm.acl.roles.owner) ||
          _vm.$hasPermission(_vm.acl.permissions.manageAccount)
      )?_c('router-link',{staticClass:"subheader-navigation-item",class:_vm.accountSettingsActive ? 'active' : '',attrs:{"to":"/settings/account-settings"}},[_vm._v(" "+_vm._s(_vm.$t("settings.tabs.account"))+" ")]):_vm._e(),(
        _vm.$hasRole(_vm.acl.roles.owner) ||
          _vm.$hasPermission(_vm.acl.permissions.manageAccount) ||
          _vm.$hasPermission(_vm.acl.permissions.managePlace)
      )?_c('router-link',{staticClass:"subheader-navigation-item",class:_vm.locationsActive ? 'active' : '',attrs:{"to":"/settings/locations"}},[_vm._v(" "+_vm._s(_vm.$t("settings.tabs.locations"))+" ")]):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }
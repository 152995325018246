<template>
  <div
    id="mobile_client_select"
    ref="mobile_client_select"
    class="offcanvas offcanvas-right"
  >
    <button id="mobile_client_select_toggle" style="display: none;"></button>
    <!--begin::Header-->
    <div
      class="offcanvas-header d-flex align-items-center justify-content-between pb-5"
    >
      <h4 class="font-weight-bold m-0">
        {{ $t("mobileClientSelect.title") }}
      </h4>
      <a
        href="#"
        class=""
        id="mobile_client_select_close"
        @click="closeOffcanvas('mobile_client_select')"
      >
        <i class="ki ki-close icon-md"></i>
      </a>
    </div>
    <!--end::Header-->
    <!--begin::Content-->
    <perfect-scrollbar
      class="offcanvas-content mr-n5 scroll"
      style="max-height: 87vh; position: relative;"
    >
      <div class="bb-input-group search-input">
        <i class="fas fa-search"></i>
        <input
          type="text"
          v-model="searchTerm"
          :placeholder="$t('mobileClientSelect.searchPlaceholder')"
        />
        <button class="bb-badge badge-rounded" @click="searchTerm = ''">
          <i class="ki ki-close icon-md"></i>
        </button>
      </div>
      <div class="clients-list">
        <div
          class="client-item"
          v-for="client in displayClients"
          :key="client.id"
          @click="selectClient(client)"
        >
          <div class="d-flex justify-content-start align-items-center">
            <div class="bb-badge">
              {{ client.initials }}
            </div>
            <div class="client-details">
              <span>{{ client.fullName }}</span>
              <small>{{ client.email }}</small>
            </div>
          </div>
          <i class="la la-angle-right"></i>
        </div>
      </div>
    </perfect-scrollbar>
    <div class="offcanvas-footer">
      <button
        class="new-client-btn"
        @click="createClient"
        v-if="
          $hasRole(acl.roles.owner) ||
            $hasPermission(acl.permissions.manageAccount)
        "
      >
        <i class="la la-plus"></i>
      </button>
    </div>
    <ClientFormModal ref="clientFormModal" />
  </div>
</template>

<script>
import MobileClientSelectLayout from "@/assets/js/layout/extended/mobile-client-select.js";
import { CLOSE_ELEMENT } from "@/core/services/store/offcanvas.module.js";
import { mapGetters } from "vuex";
import { SET_FORM_FIELD } from "@/core/services/store/form.module.js";
//import clientFormMixin from "@/core/mixins/clientFormMixin.js";
import clientFormModalMixin from "@/core/mixins/clientFormModalMixin.js";
import ClientFormModal from "@/view/layout/extras/modal/ClientFormModal.vue";

export default {
  name: "MobileClientSelect",
  mixins: [clientFormModalMixin],
  components: { ClientFormModal },
  data() {
    return {
      formKey: null,
      searchTerm: ""
    };
  },
  mounted() {
    // Init Mobile select client Panel
    MobileClientSelectLayout.init(this.$refs["mobile_client_select"]);
  },
  methods: {
    /**
     * Dispach CLOSE_ELEMENT action from offcanvas module
     * @return {void}
     */
    closeOffcanvas(elementId) {
      this.$store.dispatch(CLOSE_ELEMENT, elementId);
    },

    /**
     * Close mobileClientSelect element by imitating click on mobile_client_select_close element
     * @return {void}
     */
    close() {
      document.querySelector("#mobile_client_select_close").click();
    },

    /**
     * Save clientId value to form with this form key
     * @param {Object} client
     * @return {void}
     */
    selectClient(client) {
      this.$store.dispatch(SET_FORM_FIELD, {
        formKey: this.formKey,
        fieldName: "clientId",
        value: client.id
      });
      if (
        document.querySelector("#mobile_client_select").hasAttribute("form-key")
      ) {
        document
          .querySelector("#mobile_client_select")
          .removeAttribute("form-key");
      }
      this.close();
    }
  },
  computed: {
    ...mapGetters(["openedElements", "allClients"]),

    /**
     * Return array of clients to display
     * @return {Array}
     */
    displayClients() {
      if (this.allClients === 0) {
        return [];
      }
      return this.allClients.filter(client =>
        client.fullName.toLowerCase().includes(this.searchTerm.toLowerCase())
      );
    }
  },
  watch: {
    /**
     * Watch for opened elements change
     */
    openedElements() {
      if (this.openedElements.includes("mobile_client_select")) {
        if (
          document
            .querySelector("#mobile_client_select")
            .hasAttribute("form-key")
        ) {
          this.formKey = document
            .querySelector("#mobile_client_select")
            .getAttribute("form-key");
        }
      }
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/sass/bb-styles/components/mobile-client-select.scss";
</style>

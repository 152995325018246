<template>
  <b-modal
    id="staff_form_modal"
    ref="bvModal"
    :no-close-on-backdrop="true"
    :hide-footer="true"
  >
    <template v-slot:modal-header="{ close }">
      <div class="modal-header-buttons-container">
        <button
          class="close-modal-btn mr-7"
          id="staff_form_modal_close_btn"
          @click="close()"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="28"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-x"
          >
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
        </button>
        <button class="bb-btn btn-success header-save-btn" @click="submit()">
          {{ submitBtnText }}
        </button>
      </div>
      <div class="modal-header-title-container">
        <h2>{{ modalTitle }}</h2>
      </div>
    </template>
    <div class="bb-card basic-info-card">
      <div class="card-header">
        <h2 class="card-title">
          {{ $t("calendarFormModal.basicInfoCard.title") }}
        </h2>
      </div>
      <div class="card-body">
        <div class="card-inputs-container">
          <div class="bb-form-group">
            <label>{{
              $t("calendarFormModal.basicInfoCard.nameInput.label")
            }}</label>
            <input class="bb-input" type="text" v-model="form.fields.name" />
            <div class="bb-invalid-feedback" v-show="form.errors.name">
              {{ $t("calendarFormModal.validation.error") }}
            </div>
          </div>
          <div class="bb-form-group">
            <label>{{
              $t("calendarFormModal.basicInfoCard.titleInput.label")
            }}</label>
            <input class="bb-input" type="text" v-model="form.fields.title" />
            <p class="bb-input-description">
              {{ $t("calendarFormModal.basicInfoCard.titleInput.description") }}
            </p>
            <div class="bb-invalid-feedback" v-show="form.errors.title">
              {{ $t("calendarFormModal.validation.error") }}
            </div>
          </div>
          <div class="bb-form-group">
            <label
              >{{ $t("calendarFormModal.basicInfoCard.notesInput.label") }}
              <span class="label-description">{{
                $t(
                  "calendarFormModal.basicInfoCard.notesInput.labelDescription"
                )
              }}</span></label
            >
            <b-form-textarea
              rows="5"
              class="bb-textarea"
              v-model="form.fields.toc"
              :placeholder="
                $t('calendarFormModal.basicInfoCard.notesInput.placeholder')
              "
            ></b-form-textarea>
            <div class="bb-invalid-feedback" v-show="form.errors.toc">
              {{ $t("calendarFormModal.validation.error") }}
            </div>
          </div>
        </div>
        <hr />
        <div class="card-inputs-container">
          <p class="card-subtitle">
            {{ $t("calendarFormModal.basicInfoCard.contactTitle") }}
          </p>
          <span class="card-subtitle-description">{{
            $t("calendarFormModal.basicInfoCard.contactDescription")
          }}</span>
          <div class="form-groups-holder">
            <div class="bb-form-group">
              <label>{{
                $t("calendarFormModal.basicInfoCard.firstNameInput.label")
              }}</label>
              <input
                type="text"
                class="bb-input"
                v-model="form.fields.firstName"
              />
              <div class="bb-invalid-feedback" v-show="form.errors.firstName">
                {{ $t("calendarFormModal.validation.error") }}
              </div>
            </div>
            <div class="bb-form-group">
              <label>{{
                $t("calendarFormModal.basicInfoCard.lastNameInput.label")
              }}</label>
              <input
                type="text"
                class="bb-input"
                v-model="form.fields.lastName"
              />
              <div class="bb-invalid-feedback" v-show="form.errors.lastName">
                {{ $t("calendarFormModal.validation.error") }}
              </div>
            </div>
          </div>
          <div class="form-groups-holder">
            <div class="bb-form-group">
              <label>{{
                $t("calendarFormModal.basicInfoCard.emailInput.label")
              }}</label>
              <input
                type="email"
                class="bb-input"
                v-model="form.fields.email"
                placeholder="mail@example.com"
              />
              <div class="bb-invalid-feedback" v-show="form.errors.email">
                {{ $t("calendarFormModal.validation.error") }}
              </div>
            </div>
            <div class="bb-form-group">
              <label>{{
                $t("calendarFormModal.basicInfoCard.phoneInput.label")
              }}</label>
              <input
                type="number"
                class="bb-input"
                v-model="form.fields.phone"
              />
              <div class="bb-invalid-feedback" v-show="form.errors.phone">
                {{ $t("calendarFormModal.validation.error") }}
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div class="card-inputs-container">
          <p class="card-subtitle">
            {{ $t("calendarFormModal.basicInfoCard.employmentTitle") }}
          </p>
          <div class="form-groups-holder">
            <div class="bb-form-group">
              <label>{{
                $t("calendarFormModal.basicInfoCard.employmentStartInput.label")
              }}</label>
              <input
                type="date"
                class="bb-input"
                v-model="form.fields.employmentStartDate"
              />
              <div
                class="bb-invalid-feedback"
                v-show="form.errors.employmentStartDate"
              >
                {{ $t("calendarFormModal.validation.error") }}
              </div>
            </div>
            <div class="bb-form-group">
              <label>{{
                $t("calendarFormModal.basicInfoCard.employmentEndInput.label")
              }}</label>
              <input
                type="date"
                class="bb-input"
                v-model="form.fields.employmentEndDate"
              />
              <div
                class="bb-invalid-feedback"
                v-show="form.errors.employmentEndDate"
              >
                {{ $t("calendarFormModal.validation.error") }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bb-card places-card">
      <div class="card-header">
        <h2 class="card-title">
          {{ $t("calendarFormModal.placesCard.title") }}
        </h2>
        <span class="card-description">
          {{ $t("calendarFormModal.placesCard.description") }}</span
        >
      </div>
      <div class="card-body">
        <div class="card-inputs-container">
          <div class="bb-form-group">
            <BBCheckboxGroup
              :options="placesAsOptions"
              v-model="form.fields.placeIds"
              elementId="places_checkboxes"
              :allCheckedLabel="
                $t('calendarFormModal.placesCard.placesInput.allLabel')
              "
            />
            <div class="bb-invalid-feedback" v-show="form.errors.placeIds">
              {{ $t("calendarFormModal.validation.error") }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bb-card booking-card">
      <div class="card-header">
        <h2 class="card-title">
          {{ $t("calendarFormModal.bookingCard.title") }}
        </h2>
      </div>
      <div class="card-body">
        <div class="card-inputs-container">
          <div class="bb-form-group" v-show="!currentUser.isInLawIndustry">
            <BBSwitch
              v-model="form.fields.onlineBookings"
              :label="
                $t('calendarFormModal.bookingCard.bookingsEnabledInput.label')
              "
            />
            <p class="bb-input-description" style="margin-left: 76px">
              {{
                $t(
                  "calendarFormModal.bookingCard.bookingsEnabledInput.description"
                )
              }}
            </p>
            <div
              class="bb-invalid-feedback"
              v-show="form.errors.onlineBookings"
            >
              {{ $t("calendarFormModal.validation.error") }}
            </div>
          </div>
          <div class="bb-form-group">
            <label>{{
              $t("calendarForm.advancedSettings.cancellationMarginInput.label")
            }}</label>
            <p class="bb-input-description">
              {{
                $t(
                  "calendarForm.advancedSettings.cancellationMarginInput.description"
                )
              }}
            </p>
            <div class="margin-wrapper">
              <div class="d-flex justify-content-start align-items-center">
                <BBSelect
                  :options="daysOptions"
                  v-model="form.helpers.cancellationMarginObject.days"
                ></BBSelect>
                <span class="cancellation-margin-text">{{
                  $t(
                    "calendarForm.advancedSettings.cancellationMarginInput.daysLabel"
                  )
                }}</span>
              </div>
              <div class="d-flex justify-content-start align-items-center">
                <BBSelect
                  :options="hoursOptions"
                  v-model="form.helpers.cancellationMarginObject.hours"
                ></BBSelect>
                <span class="cancellation-margin-text">{{
                  $t(
                    "calendarForm.advancedSettings.cancellationMarginInput.hoursLabel"
                  )
                }}</span>
              </div>
              <div class="d-flex justify-content-start align-items-center">
                <BBSelect
                  :options="minutesOptions"
                  v-model="form.helpers.cancellationMarginObject.minutes"
                ></BBSelect>
                <span class="cancellation-margin-text">{{
                  $t(
                    "calendarForm.advancedSettings.cancellationMarginInput.minutesLabel"
                  )
                }}</span>
              </div>
            </div>
            <div
              class="bb-invalid-feedback"
              v-show="form.errors.cancellationMargin"
            >
              {{ form.errors.cancellationMargin }}
            </div>
          </div>

          <div class="bb-form-group">
            <label>{{
              $t("calendarForm.advancedSettings.bookingMarginInput.label")
            }}</label>
            <p class="bb-input-description">
              {{
                $t(
                  "calendarForm.advancedSettings.bookingMarginInput.description"
                )
              }}
            </p>
            <div class="margin-wrapper">
              <div class="d-flex justify-content-start align-items-center">
                <BBSelect
                  :options="daysOptions"
                  v-model="form.helpers.bookingMarginObject.days"
                ></BBSelect>
                <span class="cancellation-margin-text">{{
                  $t(
                    "calendarForm.advancedSettings.bookingMarginInput.daysLabel"
                  )
                }}</span>
              </div>
              <div class="d-flex justify-content-start align-items-center">
                <BBSelect
                  :options="hoursOptions"
                  v-model="form.helpers.bookingMarginObject.hours"
                ></BBSelect>
                <span class="cancellation-margin-text">{{
                  $t(
                    "calendarForm.advancedSettings.bookingMarginInput.hoursLabel"
                  )
                }}</span>
              </div>
              <div class="d-flex justify-content-start align-items-center">
                <BBSelect
                  :options="minutesOptions"
                  v-model="form.helpers.bookingMarginObject.minutes"
                ></BBSelect>
                <span class="cancellation-margin-text">{{
                  $t(
                    "calendarForm.advancedSettings.bookingMarginInput.minutesLabel"
                  )
                }}</span>
              </div>
            </div>
            <div class="bb-invalid-feedback" v-show="form.errors.bookingMargin">
              {{ form.errors.bookingMargin }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bb-card color-card">
      <div class="card-header">
        <h2 class="card-title">
          {{ $t("calendarFormModal.colorCard.title") }}
        </h2>
        <span class="card-description">{{
          $t("calendarFormModal.colorCard.description")
        }}</span>
      </div>
      <div class="card-body">
        <div class="card-inputs-container">
          <div class="bb-form-group">
            <label>{{
              $t("calendarFormModal.colorCard.colorInput.label")
            }}</label>
            <div class="colors-container">
              <div
                class="bb-badge badge-rounded badge-sm"
                v-for="(color, index) in availableColors"
                :key="index"
                @click="setColor(color)"
                :class="form.fields.color === color ? 'selected' : ''"
                :style="'background: ' + color"
              ></div>
            </div>
            <div class="bb-invalid-feedback" v-show="form.errors.color">
              {{ $t("calendarFormModal.validation.error") }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bb-card services-card">
      <div class="card-header">
        <h2 class="card-title">
          {{ $t("calendarFormModal.servicesCard.title") }}
        </h2>
        <span class="card-description">{{
          $t("calendarFormModal.servicesCard.description")
        }}</span>
      </div>
      <div class="card-body">
        <div class="card-inputs-container">
          <div class="services-input-container">
            <div class="choosen-services-container">
              <span>{{ selectedServicesText }}</span>
              <span
                class="selected-services-count"
                v-show="form.fields.serviceIds.length > 0"
              >
                ({{ form.fields.serviceIds.length }})</span
              >
            </div>
            <button @click="openServicesModal()">
              {{ $t("calendarFormModal.servicesCard.servicesInput.editBtn") }}
            </button>
          </div>
          <div class="bb-invalid-feedback" v-show="form.errors.serviceIds">
            {{ $t("calendarFormModal.validation.error") }}
          </div>
        </div>
      </div>
    </div>

    <div class="bb-card services-card">
      <div class="card-header">
        <h2 class="card-title">
          {{ $t("calendarFormModal.clientsCard.title") }}
        </h2>
        <span class="card-description">
          {{ $t("calendarFormModal.clientsCard.description") }}
        </span>
      </div>
      <div class="card-body">
        <div class="card-inputs-container">
          <div class="services-input-container">
            <div class="choosen-services-container">
              <span>{{ selectedClientsText }}</span>
              <span
                class="selected-services-count"
                v-show="form.fields.clientProfileIds.length > 0"
              >
                ({{ form.fields.clientProfileIds.length }})</span
              >
            </div>
            <button @click="openClientProfilesModal()">
              {{ $t("calendarFormModal.clientsCard.clientsInput.editBtn") }}
            </button>
          </div>
          <div
            class="bb-invalid-feedback"
            v-show="form.errors.clientProfileIds"
          >
            {{ $t("calendarFormModal.validation.error") }}
          </div>
        </div>
      </div>
    </div>

    <div class="bb-card permissions-card">
      <div class="card-header">
        <h2 class="card-title">
          {{ $t("calendarFormModal.permissionsCard.title") }}
        </h2>
      </div>
      <div class="card-body">
        <div class="card-inputs-container">
          <p class="permission-value">
            {{ permissionsLabels[form.helpers.permission] }}
          </p>
          <v-slider
            v-model="form.helpers.permissionValue"
            :tick-labels="permissionsLabels"
            :max="3"
            step="1"
            ticks="always"
            tick-size="8"
            color="#1A73E8"
            data-app="app"
            height="50"
          ></v-slider>
        </div>
        <div class="bb-invalid-feedback" v-show="form.errors.permissions">
          {{ $t("calendarFormModal.validation.error") }}
        </div>
      </div>
    </div>
    <b-modal
      id="select_services_modal"
      ref="selectServicesModal"
      :no-close-on-backdrop="true"
    >
      <template v-slot:modal-header="{ close }">
        <div class="modal-header-title-container">
          <h2>{{ $t("calendarFormModal.selectServicesModal.title") }}</h2>
        </div>
        <button
          class="close-modal-btn mr-7"
          id="select_services_modal_close_btn"
          @click="
            close();
            resetSelectedServices();
          "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="28"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-x"
          >
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
        </button>
      </template>
      <div class="search-services-container">
        <div class="bb-input-group search-services-input">
          <i class="fas fa-search"></i>
          <input
            type="text"
            v-model="servicesSearch"
            :placeholder="
              $t(
                'calendarFormModal.selectServicesModal.searchInput.placeholder'
              )
            "
          />
        </div>
      </div>
      <div class="services-list">
        <BBCheckboxGroup
          :options="servicesOptions"
          v-model="form.fields.serviceIds"
          :allCheckedLabel="
            $t('calendarFormModal.selectServicesModal.allServicesLabel')
          "
          elementId="service_checkboxes"
          ref="serviceCheckboxes"
        />
      </div>
      <template v-slot:modal-footer="{ close }">
        <button
          class="bb-btn btn-outline"
          @click="
            close();
            resetSelectedServices();
          "
        >
          {{ $t("calendarFormModal.selectServicesModal.closeBtn") }}
        </button>
        <button
          class="bb-btn btn-success"
          @click="
            close();
            setSelectedServices();
          "
        >
          {{ $t("calendarFormModal.selectServicesModal.saveBtn") }}
        </button>
      </template>
    </b-modal>

    <b-modal
      id="select_clients_modal"
      ref="selectClientProfilesModal"
      :no-close-on-backdrop="true"
    >
      <template v-slot:modal-header="{ close }">
        <div class="modal-header-title-container">
          <h2>{{ $t("calendarFormModal.selectClientsModal.title") }}</h2>
        </div>
        <button
          class="close-modal-btn mr-7"
          id="select_services_modal_close_btn"
          @click="
            close();
            resetSelectedClientProfiles();
          "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="28"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-x"
          >
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
        </button>
      </template>
      <div class="search-services-container">
        <div class="bb-input-group search-services-input">
          <i class="fas fa-search"></i>
          <input
            type="text"
            v-model="clientProfileSearch"
            :placeholder="
              $t('calendarFormModal.selectClientsModal.searchInput.placeholder')
            "
          />
        </div>
      </div>
      <div class="services-list">
        <BBCheckboxGroup
          :options="clientsAsOptions"
          v-model="form.fields.clientProfileIds"
          :allCheckedLabel="
            $t('calendarFormModal.selectClientsModal.allClientsLabel')
          "
          elementId="clients_checkboxes"
          ref="clientProfilesCheckboxes"
        />
      </div>
      <template v-slot:modal-footer="{ close }">
        <button
          class="bb-btn btn-outline"
          @click="
            close();
            resetSelectedClientProfiles();
          "
        >
          {{ $t("calendarFormModal.selectClientsModal.closeBtn") }}
        </button>
        <button
          class="bb-btn btn-success"
          @click="
            close();
            setSelectedClientProfiles();
          "
        >
          {{ $t("calendarFormModal.selectClientsModal.saveBtn") }}
        </button>
      </template>
    </b-modal>

    <div class="mobile-footer">
      <button class="bb-btn btn-success" @click="submit()">
        {{ submitBtnText }}
      </button>
    </div>
  </b-modal>
</template>

<script>
import calendarForm from "@/core/services/forms/calendarForm.js";
import formMixin from "@/core/mixins/formMixin.js";
import { mapGetters } from "vuex";
import BBCheckboxGroup from "@/view/content/BBForm/BBCheckboxGroup.vue";
import BBSwitch from "@/view/content/BBForm/BBSwitch.vue";
import BBSelect from "@/view/content/BBForm/BBSelect.vue";
import serviceMapper from "@/core/services/helpers/serviceMapper.js";
import { SET_ALERT_MESSAGES } from "@/core/services/store/alert.module.js";
import { GET_PLACES } from "@/core/services/store/places.module.js";
import aclService from "@/core/services/acl.service.js";
export default {
  name: "CalendarFormModal",
  mixins: [formMixin],
  components: { BBCheckboxGroup, BBSwitch, BBSelect },
  data() {
    return {
      servicesSearch: "",
      clientProfileSearch: "",
      selectedServices: [],
      selectedClientProfiles: [],
      permissionsLabels: [
        this.$t("calendarForm.basicSettings.roleInput.manageCalendarLabel"),
        this.$t("calendarForm.basicSettings.roleInput.manageBookingsLabel"),
        this.$t("calendarForm.basicSettings.roleInput.managePlaceLabel"),
        this.$t("calendarForm.basicSettings.roleInput.manageAccountLabel"),
      ],
      availableColors: [
        "#1A73E8",
        "#F64E60",
        "#1BC5BD",
        "#FFC812",
        "#91E3EE",
        "#E2A6E6",
        "#FF9CBB",
        "#FFBF69",
        "#BBC1E8",
        "#A6E5BD",
        "#134D97",
        "#588157",
        "#0C0F0A",
        "#9D4EDD",
        "#6C757D",
        "#FF006E",
        "#E9EDC9",
        "#E5989B",
        "#006D77",
      ],
    };
  },
  beforeMount() {
    // Get form object from calendarForm service
    this.form = JSON.parse(JSON.stringify(calendarForm));
  },
  methods: {
    /**
     * Show the modal
     * @return {void}
     */
    show() {
      this.$refs.bvModal.show();
      this.selectedServices = this.form.fields.serviceIds;
    },
    /**
     * Close the modal
     * @return {void}
     */
    closeModal() {
      document.querySelector("#staff_form_modal_close_btn").click();
    },

    /**
     * Set selected color
     * @param {Object} color
     * @return {void}
     */
    setColor(color) {
      this.form.fields.color = color;
    },

    /**
     * Open select services modal
     * @return {void}
     */
    openServicesModal() {
      this.$refs.selectServicesModal.show();
    },

    /**
     * Reset selectedServices data property
     * @return {void}
     */
    resetSelectedServices() {
      this.form.fields.serviceIds = this.selectedServices;
    },

    /**
     * Set serviceIds form field
     * @return {void}
     */
    setSelectedServices() {
      this.selectedServices = this.form.fields.serviceIds;
    },

    /**
     * Open select client profiles modal
     * @return {void}
     */
    openClientProfilesModal() {
      this.$refs.selectClientProfilesModal.show();
    },

    /**
     * Reset selectedCLientProfiles data property
     * @return {void}
     */
    resetSelectedClientProfiles() {
      this.form.fields.clientProfileIds = this.selectedClientProfiles;
    },

    /**
     * Set clientProfileIds form field
     * @return {void}
     */
    setSelectedClientProfiles() {
      this.selectedClientProfiles = this.form.fields.clientProfileIds;
    },

    /**
     * Update form's permissions field
     * @return {void}
     */
    updatePermissionsField() {
      this.form.fields.permissions = [];
      switch (this.form.helpers.permissionValue) {
        case 0:
          this.form.fields.permissions.push(
            aclService.permissions.manageOwnCalendar
          );
          break;
        case 1:
          this.form.fields.permissions.push(
            aclService.permissions.manageBookings
          );
          break;
        case 2:
          this.form.fields.permissions.push(aclService.permissions.managePlace);
          break;
        case 3:
          this.form.fields.permissions.push(
            aclService.permissions.manageAccount
          );
          break;
        default:
          this.form.fields.permissions.push(
            aclService.permissions.manageOwnCalendar
          );
          break;
      }
    },

    /**
     * Return number of minutes from marginObject that contains days, hours and minutes properties
     * @param {Object} marginObject
     * @return {Number}
     */
    getMinutes(marginObject) {
      let daysInMinutes = parseInt(marginObject.days) * 60 * 24;
      let hoursInMinutes = parseInt(marginObject.hours) * 60;
      let result =
        daysInMinutes + hoursInMinutes + parseInt(marginObject.minutes);
      return result;
    },

    rearrangeForm() {
      this.form.fields.cancellationMargin = this.getMinutes(
        this.form.helpers.cancellationMarginObject
      );
      this.form.fields.bookingMargin = this.getMinutes(
        this.form.helpers.bookingMarginObject
      );
    },

    /**
     * Submit the form
     * @return {void}
     */
    submit() {
      this.updatePermissionsField();
      this.rearrangeForm();
      this.submitForm()
        .then((response) => {
          this.$store.dispatch(SET_ALERT_MESSAGES, {
            successMessages: [response.data.message],
            errorMessages: null,
          });
          this.closeModal();
          this.$store.dispatch(GET_PLACES);
        })
        .catch((error) =>
          this.$store.dispatch(SET_ALERT_MESSAGES, {
            successMessages: null,
            errorMessages: [error.response.data.message],
          })
        );
    },
  },
  computed: {
    ...mapGetters([
      "placesAsOptions",
      "allServiceCategories",
      "clientsAsOptions",
      "currentUser"
    ]),

    /**
     * Represent modal title
     * @return {String}
     */
    modalTitle() {
      return this.form.mode === "edit"
        ? this.$t("calendarFormModal.editTitle")
        : this.$t("calendarFormModal.createTitle");
    },
    /**
     * Represent submit button text
     * @return {String}
     */
    submitBtnText() {
      return this.form.mode === "edit"
        ? this.$t("calendarFormModal.editBtn")
        : this.$t("calendarFormModal.createBtn");
    },

    /**
     * Return array of services options
     * @return {Array}
     */
    servicesOptions() {
      let services = [];
      this.allServiceCategories.forEach((serviceCategory) => {
        serviceCategory.services.forEach((service) => services.push(service));
      });
      return serviceMapper.mapServicesAsOptions(services);
    },

    /**
     * Return array of services options to display
     * @return {Array}
     */
    displayServicesOptions() {
      return this.servicesOptions.filter((option) =>
        option.text.toLowerCase().includes(this.servicesSearch.toLowerCase())
      );
    },

    /**
     * Return array of client profiles to display
     * @return {Array}
     */
    displayClientProfileOptions() {
      return this.clientsAsOptions.filter((option) =>
        option.text
          .toLowerCase()
          .includes(this.clientProfileSearch.toLowerCase())
      );
    },

    /**
     * Return selected services text
     * @return {String}
     */
    selectedServicesText() {
      if (this.form.fields.serviceIds.length === this.servicesOptions.length) {
        return this.$t(
          "calendarFormModal.servicesCard.servicesInput.allSelectedLabel"
        );
      }

      if (this.form.fields.serviceIds.length > 0) {
        return this.$t(
          "calendarFormModal.servicesCard.servicesInput.countSelectedLabel"
        );
      }

      return this.$t(
        "calendarFormModal.servicesCard.servicesInput.noSelectedLabel"
      );
    },

    /**
     * Return array of days options
     * @return {Array}
     */
    daysOptions() {
      let options = [];
      for (let i = 0; i < 15; i++) {
        let option = {
          text: i.toString(),
          value: i.toString(),
        };
        options.push(option);
      }
      return options;
    },

    /**
     * Return array of hours options
     * @return {Array}
     */
    hoursOptions() {
      let options = [];
      for (let i = 0; i < 24; i++) {
        let option = {
          text: i.toString(),
          value: i.toString(),
        };
        options.push(option);
      }
      return options;
    },

    /**
     * Return array of minutes options
     * @return {Array}
     */
    minutesOptions() {
      let options = [];
      for (let i = 0; i < 60; i++) {
        let option = {
          text: i.toString(),
          value: i.toString(),
        };
        options.push(option);
      }
      return options;
    },

    /*
     * Return selected clients text
     * @return {String}
     */
    selectedClientsText() {
      if (
        this.form.fields.clientProfileIds.length ===
        this.clientsAsOptions.length
      ) {
        return this.$t(
          "calendarFormModal.clientsCard.clientsInput.allSelectedLabel"
        );
      }

      if (this.form.fields.clientProfileIds.length > 0) {
        return this.$t(
          "calendarFormModal.clientsCard.clientsInput.countSelectedLabel"
        );
      }

      return this.$t(
        "calendarFormModal.clientsCard.clientsInput.noSelectedLabel"
      );
    },
  },

  watch: {
    /**
     * Watch for displayServicesOptions change
     */
    displayServicesOptions: {
      handler() {
        let idsToDisplay = this.displayServicesOptions.map((option) => {
          return option.value;
        });
        let component = this.$refs.serviceCheckboxes;
        if (component) {
          let checkboxGroupComponent = component.$children[1];
          checkboxGroupComponent.$el.childNodes.forEach((child) => {
            if (
              !idsToDisplay.includes(
                parseInt(child.childNodes[0].getAttribute("value"))
              )
            ) {
              child.style.display = "none";
            } else {
              child.style.display = "block";
            }
          });
        }
      },
    },
    /**
     * Watch for displayClientProfileOptions change
     */
    displayClientProfileOptions: {
      handler() {
        let idsToDisplay = this.displayClientProfileOptions.map((option) => {
          return option.value;
        });
        let component = this.$refs.clientProfilesCheckboxes;
        if (component) {
          let checkboxGroupComponent = component.$children[1];
          checkboxGroupComponent.$el.childNodes.forEach((child) => {
            if (
              !idsToDisplay.includes(
                parseInt(child.childNodes[0].getAttribute("value"))
              )
            ) {
              child.style.display = "none";
            } else {
              child.style.display = "block";
            }
          });
        }
      },
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/sass/bb-styles/modals/staff-form-modal.scss";
</style>

<template>
  <b-dropdown
    size="sm"
    variant="link"
    toggle-class="custom-v-dropdown"
    ref="userDropdown"
    right
    no-caret
    no-flip
  >
    <template v-slot:button-content>
      <a href="#" class="btn btn-icon" data-toggle="dropdown">
        <!-- <span class="symbol symbol-35 symbol-primary">
          <span
            v-if="true"
            class="symbol-label font-size-h5 font-weight-bold user-initials"
          >
            {{ currentUser.initials }}
          </span>
        </span> -->
        <div class="bb-badge badge-rounded user-initials">
          {{ currentUser.initials }}
        </div>
        <span class="user-name">{{ currentUser.fullName }}</span>
        <span class="caret">
          <i class="la la-angle-down"></i>
        </span>
      </a>
    </template>
    <!--begin::Navigation-->
    <div class="navi navi-hover min-w-md-200px" @click="closeDropdown()">
      <router-link :to="{ name: 'profile' }">
        <b-dropdown-text tag="div" class="navi-item">
          <!-- <i class="far fa-user"></i> -->
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-user"
          >
            <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
            <circle cx="12" cy="7" r="4"></circle>
          </svg>
          {{ $t("menu.profile") }}
        </b-dropdown-text>
      </router-link>
      <router-link
        :to="{ name: 'settings' }"
        v-if="
          $hasRole(acl.roles.owner) ||
            $hasPermission(acl.permissions.manageAccount) ||
            $hasPermission(acl.permissions.managePlace)
        "
      >
        <b-dropdown-text tag="div" class="navi-item">
          <!-- <i class="flaticon2-gear"></i> -->
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-settings"
          >
            <circle cx="12" cy="12" r="3"></circle>
            <path
              d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"
            ></path>
          </svg>
          {{ $t("menu.settings") }}
        </b-dropdown-text>
      </router-link>
      <b-dropdown-text tag="div" class="navi-item">
        <!-- <i class="flaticon2-chat-1"></i> -->
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="22"
          height="22"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-message-circle"
        >
          <path
            d="M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z"
          ></path>
        </svg>
        {{ $t("menu.contactSupport") }}
      </b-dropdown-text>
      <b-dropdown-text tag="div" class="navi-item" @click="changeLanguage()">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="22"
          height="22"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-globe"
        >
          <circle cx="12" cy="12" r="10"></circle>
          <line x1="2" y1="12" x2="22" y2="12"></line>
          <path
            d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z"
          ></path>
        </svg>
        {{ currentLanguageName }}
      </b-dropdown-text>
      <b-dropdown-text tag="div" class="navi-item" @click="logout()">
        <!-- <i class="fas fa-sign-out-alt"></i> -->
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="22"
          height="22"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-log-out"
        >
          <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
          <polyline points="16 17 21 12 16 7"></polyline>
          <line x1="21" y1="12" x2="9" y2="12"></line>
        </svg>
        {{ $t("menu.logout") }}
      </b-dropdown-text>
    </div>
    <ChangeLanguageModal ref="changeLanguageModal" />
  </b-dropdown>
</template>

<script>
import { mapGetters } from "vuex";
import { LOGOUT } from "@/core/services/store/auth.module";
import i18nService from "@/core/services/i18n.service.js";
import ChangeLanguageModal from "@/view/layout/extras/modal/ChangeLanguageModal.vue";

export default {
  name: "DropdownUser",
  components: { ChangeLanguageModal },
  methods: {
    /**
     * Dispatch LOGOUT action from auth module
     * @return {void}
     */
    logout() {
      this.$store.dispatch(LOGOUT);
    },

    /**
     * Close dropdown
     * @return {void}
     */
    closeDropdown() {
      this.$refs.userDropdown.hide();
    },

    /**
     * Open change language modal
     * @return {void}
     */
    changeLanguage() {
      this.$refs.changeLanguageModal.show();
      this.closeDropdown();
    }
  },
  computed: {
    ...mapGetters(["currentUser"]),

    /**
     * Return name of current language
     * @return {String}
     */
    currentLanguageName() {
      let selectedLanguage = i18nService.languages.find(
        language => language.lang === i18nService.getActiveLanguage()
      );
      return selectedLanguage.name;
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/sass/bb-styles/components/dropdown-user.scss";
</style>

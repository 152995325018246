<template>
  <div
    id="mobile_service_select"
    ref="mobile_service_select"
    class="offcanvas offcanvas-right"
  >
    <button id="mobile_service_select_toggle" style="display: none;"></button>
    <!--begin::Header-->
    <div
      class="offcanvas-header d-flex align-items-center justify-content-between pb-5"
    >
      <h4 class="font-weight-bold m-0">
        {{ $t("mobileServiceSelect.title") }}
      </h4>
      <a
        href="#"
        class=""
        id="mobile_service_select_close"
        @click="closeOffcanvas('mobile_service_select')"
      >
        <i class="ki ki-close icon-md"></i>
      </a>
    </div>
    <!--end::Header-->
    <!--begin::Content-->
    <perfect-scrollbar
      class="offcanvas-content mr-n5 scroll"
      style="max-height: 87vh; position: relative;"
    >
      <div class="bb-input-group search-input">
        <i class="fas fa-search"></i>
        <input
          type="text"
          v-model="searchTerm"
          :placeholder="$t('mobileServiceSelect.searchPlaceholder')"
        />
        <button class="bb-badge badge-rounded" @click="searchTerm = ''">
          <i class="ki ki-close icon-md"></i>
        </button>
      </div>
      <div class="service-categories-list">
        <div
          class="service-category-item"
          v-for="serviceCategory in displayServices"
          :key="serviceCategory.id"
        >
          <span class="service-category-name">{{ serviceCategory.name }}</span>
          <div class="services-list">
            <div
              class="service-item"
              v-for="service in serviceCategory.services"
              :key="service.id"
              @click="selectService(service)"
            >
              <div class="service-item-left">
                <span>{{ service.name }}</span>
                <small>{{ service.duration + " minutes" }}</small>
              </div>
              <div class="service-item-right">
                <span>{{ service.price + " RSD" }}</span>
                <button><i class="la la-angle-right"></i></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </perfect-scrollbar>
    <div class="offcanvas-footer">
      <button
        class="new-service-btn"
        @click="createService({ placeId: currentPlace.id })"
        v-if="
          $hasRole(acl.roles.owner) ||
            $hasPermission(acl.permissions.manageAccount)
        "
      >
        <i class="la la-plus"></i>
      </button>
    </div>
    <ServiceFormModal ref="serviceFormModal" />
  </div>
</template>

<script>
import MobileServiceSelectLayout from "@/assets/js/layout/extended/mobile-service-select.js";
import { CLOSE_ELEMENT } from "@/core/services/store/offcanvas.module.js";
//import serviceFormMixin from "@/core/mixins/serviceFormMixin.js";
import serviceFormModalMixin from "@/core/mixins/serviceFormModalMixin.js";
import { mapGetters } from "vuex";
import { SET_FORM_FIELD } from "@/core/services/store/form.module.js";
import ServiceFormModal from "@/view/layout/extras/modal/ServiceFormModal.vue";

export default {
  name: "MobileServiceSelect",
  mixins: [serviceFormModalMixin],
  components: { ServiceFormModal },
  data() {
    return {
      formKey: null,
      searchTerm: ""
    };
  },
  mounted() {
    // Init Mobile select service Panel
    MobileServiceSelectLayout.init(this.$refs["mobile_service_select"]);
  },
  methods: {
    /**
     * Dispach CLOSE_ELEMENT action from offcanvas module
     * @return {void}
     */
    closeOffcanvas(elementId) {
      this.$store.dispatch(CLOSE_ELEMENT, elementId);
    },

    /**
     * Close mobileServiceSelect element by imitating click on mobile_service_select_close element
     * @return {void}
     */
    close() {
      document.querySelector("#mobile_service_select_close").click();
    },

    /**
     * Save serviceId value to form with this form key
     * @param {Object} client
     * @return {void}
     */
    selectService(service) {
      this.$store.dispatch(SET_FORM_FIELD, {
        formKey: this.formKey,
        fieldName: "serviceId",
        value: service.id
      });
      if (
        document
          .querySelector("#mobile_service_select")
          .hasAttribute("form-key")
      ) {
        document
          .querySelector("#mobile_service_select")
          .removeAttribute("form-key");
      }
      this.close();
    }
  },
  computed: {
    ...mapGetters(["openedElements", "currentPlace"]),

    /**
     * Get form from form module
     * @return {Object|null}
     */
      formState() {
        if (this.formKey) {
            return this.$store.getters.getForm(this.formKey);
        }
        return null;
    },

    /**
     * Return array of service categories with service to display
     * @return {Array}
     */
    displayServices() {
      let serviceCategories = [];
      if (this.currentPlace && this.currentPlace.services) {
        let allServiceCategories = JSON.parse(
          JSON.stringify(this.currentPlace.services)
        );
        if (allServiceCategories.length === 0) {
          return [];
        }

        if(this.formState && this.formState.key === "appointmentForm") {
          if(this.formState.fields.type === "REGULAR") {
            allServiceCategories = allServiceCategories.map(function(serviceCategory) {
              let serviceCategoryCopy = JSON.parse(JSON.stringify(serviceCategory))
              serviceCategoryCopy.services = serviceCategoryCopy.services.filter(function(service) {
                return service.isGroupService == false
              })
              return serviceCategoryCopy.services.length > 0 ? serviceCategoryCopy : null
            }).filter(function(serviceCategory) {
              return serviceCategory !== null
            })
          }

          if(this.formState.fields.type === "GROUP") {
            allServiceCategories = allServiceCategories.map(function(serviceCategory) {
              let serviceCategoryCopy = JSON.parse(JSON.stringify(serviceCategory))
              serviceCategoryCopy.services = serviceCategoryCopy.services.filter(function(service) {
                return service.isGroupService == true
              })
              return serviceCategoryCopy.services.length > 0 ? serviceCategoryCopy : null
            }).filter(function(serviceCategory) {
              return serviceCategory !== null
            })
          }
        }

        allServiceCategories.forEach(serviceCategory => {
          serviceCategory.services = serviceCategory.services.filter(service =>
            service.name.toLowerCase().includes(this.searchTerm.toLowerCase())
          );
          if (serviceCategory.services.length > 0) {
            serviceCategories.push(serviceCategory);
          }
        });
      }

      return serviceCategories;
    }
  },
  watch: {
    /**
     * Watch for opened elements change
     */
    openedElements() {
      if (this.openedElements.includes("mobile_service_select")) {
        if (
          document
            .querySelector("#mobile_service_select")
            .hasAttribute("form-key")
        ) {
          this.formKey = document
            .querySelector("#mobile_service_select")
            .getAttribute("form-key");
        }
      }
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/sass/bb-styles/components/mobile-service-select.scss";
</style>

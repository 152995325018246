<template>
  <div
    id="opening_hours_form"
    ref="opening_hours_form"
    class="offcanvas offcanvas-right offcanvas-xl"
    v-click-outside="handleOutsideClick"
  >
    <button id="opening_hours_form_toggle" style="display: none;"></button>
    <!--begin::Header-->
    <div
      class="offcanvas-header d-flex align-items-center justify-content-between pb-5"
    >
      <h4 class="font-weight-bold m-0">
        {{ $t("openingHoursForm.title") }}
      </h4>
      <a
        href="#"
        class=""
        id="opening_hours_form_close"
        @click="closeOffcanvas('opening_hours_form')"
      >
        <i class="ki ki-close icon-md"></i>
      </a>
    </div>
    <!--end::Header-->
    <!--begin::Content-->
    <perfect-scrollbar
      class="offcanvas-content mr-n5 scroll"
      style="max-height: 85vh; position: relative;"
    >
      <div v-if="form.helpers.openingHours">
        <div
          class="daily-working-hours"
          :class="key === 'sunday' ? 'mb-14' : ''"
          v-for="(dailyOpeningHours, key) in form.helpers.openingHours"
          :key="key"
        >
          <div class="daily-working-hours-switch">
            <BBSwitch
              :label="$t('weekdays.' + key)"
              v-model="dailyOpeningHours.on"
            />
            <span
              v-show="!dailyOpeningHours.on"
              class="mobile-daily-working-hours-closed"
              >{{ $t("openingHoursForm.closedLabel") }}</span
            >
          </div>
          <div
            v-show="dailyOpeningHours.on"
            class="daily-working-hours-selects"
          >
            <div class="daily-working-hours-select">
              <BBSelect
                :options="getTimeOptions()"
                :placeholderHidden="true"
                v-model="dailyOpeningHours.from"
              ></BBSelect>
              <span> - </span>
              <BBSelect
                :options="getTimeOptions(dailyOpeningHours.from)"
                :placeholderHidden="true"
                v-model="dailyOpeningHours.to"
              ></BBSelect>
            </div>
          </div>
          <span
            v-show="!dailyOpeningHours.on"
            class="daily-working-hours-closed"
            >{{ $t("openingHoursForm.closedLabel") }}</span
          >
        </div>
        <div class="bb-invalid-feedback" v-show="form.errors.openingHours">
          {{ form.errors.openingHours }}
        </div>
      </div>
    </perfect-scrollbar>
    <!--end::Content-->
    <div class="offcanvas-footer">
      <div class="d-flex justify-content-between align-items-center">
        <button class="bb-btn btn-success ml-2" @click="submit()">
          {{ $t("openingHoursForm.saveBtn") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import OpeningHoursFormLayout from "@/assets/js/layout/extended/opening-hours-form.js";
import { CLOSE_ELEMENT } from "@/core/services/store/offcanvas.module.js";
import placeForm from "@/core/services/forms/placeForm.js";
import formMixin from "@/core/mixins/formMixin.js";
import { mapGetters } from "vuex";
import BBSwitch from "@/view/content/BBForm/BBSwitch.vue";
import BBSelect from "@/view/content/BBForm/BBSelect.vue";
import { SET_ALERT_MESSAGES } from "@/core/services/store/alert.module.js";
import { GET_PLACES } from "@/core/services/store/places.module.js";
import { enableBodyScroll } from "body-scroll-lock";

export default {
  name: "OpeningHoursForm",
  mixins: [formMixin],
  components: { BBSwitch, BBSelect },
  beforeMount() {
    this.form = JSON.parse(JSON.stringify(placeForm));
  },
  mounted() {
    // Init Calendar Form Panel
    OpeningHoursFormLayout.init(this.$refs["opening_hours_form"]);
  },
  methods: {
    /**
     * Handle outside click
     * @return {void}
     */
    handleOutsideClick(event) {
      if (event.target.classList.contains("offcanvas-overlay")) {
        this.closeForm();
      }
    },

    /**
     * Dispach CLOSE_ELEMENT action from offcanvas module
     * @return {void}
     */
    closeOffcanvas(elementId) {
      this.$store.dispatch(CLOSE_ELEMENT, elementId);
      let targetElement = document.querySelector("#opening_hours_form");
      enableBodyScroll(targetElement);
    },

    /**
     * Close the Opening hours form by imitating click on #opening_hours_form_close element
     * @return {void}
     */
    closeForm() {
      document.querySelector("#opening_hours_form_close").click();
    },

    /**
     * Update opening hours fields based on opening hours helper
     * @return {void}
     */
    updateOpeningHoursField() {
      for (const [key, value] of Object.entries(
        this.form.helpers.openingHours
      )) {
        if (value.on && value.from && value.to) {
          this.form.fields.openingHours[key].from = value.from;
          this.form.fields.openingHours[key].to = value.to;
        } else {
          this.form.fields.openingHours[key].from = null;
          this.form.fields.openingHours[key].to = null;
        }
      }
    },

    /**
     * Submit the form
     * @return {void}
     */
    submit() {
      this.updateOpeningHoursField();
      this.submitForm()
        .then(response => {
          this.$store.dispatch(SET_ALERT_MESSAGES, {
            successMessages: [response.data.message],
            errorMessages: null
          });
          this.closeForm();
          this.$store.dispatch(GET_PLACES);
        })
        .catch(error => {
          this.$store.dispatch(SET_ALERT_MESSAGES, {
            successMessages: null,
            errorMessages: [error.response.data.message]
          });
        });
    },

    /**
     * Return array of time options
     * @param {String|null} startTime
     * @return {Array}
     */
    getTimeOptions(startTime = null) {
      let options = [];
      let minTime = 0;
      let maxTime = 24;
      if (startTime) {
        let startTimeArray = startTime.split(":");
        minTime = parseInt(startTimeArray[0]);
      }
      for (let i = minTime; i < maxTime; i++) {
        let maxMins = maxTime === i ? 5 : 60;
        for (let j = 0; j < maxMins; j += 5) {
          let timeSlot =
            String(i).padStart(2, "0") + ":" + String(j).padStart(2, "0");
          let option = {
            text: timeSlot,
            value: timeSlot
          };
          options.push(option);
        }
      }

      return options;
    }
  },
  computed: {
    ...mapGetters(["openedElements"])
  }
};
</script>

<style lang="scss">
@import "@/assets/sass/bb-styles/forms/opening-hours-form.scss";
</style>
